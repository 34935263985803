import { Button, Switch } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

import { HiMiniSignal } from "react-icons/hi2";
import { IoIosAdd } from "react-icons/io";
import { IoWalletOutline } from "react-icons/io5";
import { SiMastercard } from "react-icons/si";

import KycStatusInfo from "../../../../components/kycInformation";
import PaymentModal from "./paymentModal";

function Section1(props: any) {
  const userdetails = useSelector((state: any) => state.auth.user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [process, setProcess] = useState<any>("");

  const [kycModal, setKYCModal] = useState(false);

  const handleSubmit = () => {
    if (props?.kycStatus !== "accepted") {
      setKYCModal(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const findCashIndex = (val: String) => {
    const result = props?.data.filter((item: any) => {
      if (item?.metal_type === val) {
        return item?.balance;
      }
    });
    return result?.[0]?.balance?.toFixed(2);
  };

  return (
    <div className="app-WalletScreen-Section1">
      <div className="app-WalletScreen-Section1-Box1">
        <div className="app-WalletScreen-Section1-Box2">
          <IoWalletOutline />
        </div>
        My wallet
      </div>
      <div className="app-WalletScreen-Section1-Box1">
        <div style={{ flex: 1 }}></div>
        <Button className="app-WalletScreen-Section1-Box2 ">
          <div
            className="app-WalletScreen-Section1-text1"
            onClick={() => {
              setProcess("withdrawal");
              handleSubmit();
            }}
          >
            Withdraw
          </div>
        </Button>
        <Button
          className="app-WalletScreen-Section1-Box2 "
          onClick={() => {
            setProcess("deposited");
            handleSubmit();
          }}
        >
          <div className="app-WalletScreen-Section1-text1">
            {" "}
            <IoIosAdd size={20} /> Add money
          </div>
        </Button>
      </div>
      <br />
      <div className="app-WalletScreen-Section1-Amount">
        <div style={{ display: "flex", alignItems: "center" }}>
          <HiMiniSignal size={30} />
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div className="app-WalletScreen-Section1-Box3">Active</div>
          <div style={{ flex: 1 }}></div>
          <SiMastercard size={30} />
        </div>
        <br />
        <div style={{ display: "flex", alignItems: "end" }}>
          <div className="app-WalletScreen-Section1-Box4">
            Savings
            <span className="app-WalletScreen-Section1-text2">
              {findCashIndex("cash")}
              <span className="app-WalletScreen-Section1-text1">AED</span>
            </span>
          </div>{" "}
          <div style={{ flex: 1 }}></div>
          <div>
            <span>
              <Switch />
            </span>
          </div>
        </div>
      </div>
      <PaymentModal
        userId={props?.user.id}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        process={process}
        color={"gray"}
      />
      <KycStatusInfo
        data={userdetails.kyc_status}
        User={userdetails}
        isOpen={kycModal}
        onClose={() => setKYCModal(false)}
      />
    </div>
  );
}

export default Section1;
