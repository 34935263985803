import { Select } from "antd";
import React, { useState } from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { setDirection } from "../../Redux/slice/languageSlice";
import { CiGlobe } from "react-icons/ci";

const LanguageSelector = (props: any) => {
  const selectedLanguage = localStorage.getItem("i18nextLng") || "en";
  const [selected, setselected] = useState(selectedLanguage.toUpperCase());
  const dispatch = useDispatch();

  const handleChangeLanguage = (value: any) => {
    if (value === "ar") {
      dispatch(setDirection("RTL"));
    } else {
      dispatch(setDirection("LTR"));
    }

    setselected(value.toUpperCase());
    const language = value;
    localStorage.setItem("i18nextLng", language);
    i18next.changeLanguage(language);
  };

  return (
    <div className="WebsiteHeader-select">
      <Select
        bordered={false}
        suffixIcon={<CiGlobe size={17} color={props?.color} />}
        defaultValue={selected}
        style={{ width: "100%" }}
        onChange={(value) => handleChangeLanguage(value)}
      >
        {[
          {
            id: 1,
            title: "English",
            value: "en",
          },
          {
            id: 2,
            title: "Arabic",
            value: "ar",
          },
        ]?.map((val: any) => (
          <Select.Option value={val.value}>{val.title}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default LanguageSelector;
