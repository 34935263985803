import { HiUserCircle } from "react-icons/hi";
import Clock from "react-live-clock";
function Header() {
  return (
    <div className="AdminRoutes-Header">
      <div className="AdminRoutes-HeaderBox">
        <div className="AdminRoutes-Headertxt1">Admin Panel</div>
        <div style={{ flex: 1 }}></div>
        <div className="AdminRoutes-HeaderTimeBox">
          <Clock format={"h:mm:ss A"} ticking={true} />
        </div>
        <div className="AdminRoutes-HeaderBox2">
          <div>
            <HiUserCircle size={40} color="#dbdbdb" />
          </div>
          <div style={{ marginLeft: 10 }}>
            <div className="AdminRoutes-Headertxt2">Lsg Admin</div>
            <div className="AdminRoutes-Headertxt3">admin</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
