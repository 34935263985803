import React, { useState } from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Popover } from "antd";
import LanguageSelector from "./languageSelector";
import { useSelector } from "react-redux";

import Logo from "../../assets/images/logo-2.png";
import { useNavigate } from "react-router-dom";
import { AiFillApple } from "react-icons/ai";
import { FaGooglePlay } from "react-icons/fa6";
import { AiOutlineMenu } from "react-icons/ai";
import { LuUserCircle } from "react-icons/lu";

import LoginModal from "../LoginModal";
import ProfilePopover from "../LoginModal/profilePopover";
import SideDrawer from "./mobileDrawer";
import { useTranslation } from "react-i18next";

function WebsiteHeader() {
  const User = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [login, setLogin] = useState(false);
  const [mobDrawer, setMobDrawer] = useState(false);

  return (
    <div className="WebsiteHeader">
      <Container>
        <Row>
          <Col sm={4} xs={8}>
            <div className="WebsiteHeader-logo" onClick={() => navigate("/")}>
              <img src={Logo} />
              <div>
                LSG <span className="WebsiteHeader-text1">Digital Gold</span>
              </div>
            </div>
          </Col>
          <Col sm={8} xs={4}>
            <div className="WebsiteHeader-box1">
              <div
                className="WebsiteHeader-text2"
                onClick={() => navigate("/")}
              >
                {t("home")}
              </div>
              <div
                className="WebsiteHeader-text2"
                onClick={() => navigate("/about")}
              >
                {t("About")}
              </div>
              <div
                className="WebsiteHeader-text2"
                onClick={() => navigate("/contact")}
              >
                {t("contact_us")}
              </div>
              <a
                className="WebsiteHeader-text2"
                href="https://apps.apple.com/in/app/lucky-star-digi-gold/id6504337889"
                target="_blank"
              >
                <AiFillApple size={24} />
              </a>
              <a
                className="WebsiteHeader-text2"
                href="https://play.google.com/store/apps/details?id=com.lsgdigitalgold&pli=1"
                target="_blank"
              >
                <FaGooglePlay />
              </a>
              <div>
                <LanguageSelector />
              </div>
              {User?.auth ? (
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  arrow={false}
                  content={<ProfilePopover data={User?.user} />}
                  overlayInnerStyle={{ padding: 0, margin: 0 }}
                >
                  <div className="WebsiteHeader-profileIcon">
                    {User?.user?.image ? (
                      <img src={User?.user?.image} />
                    ) : (
                      <LuUserCircle />
                    )}
                  </div>
                </Popover>
              ) : (
                <div
                  className="WebsiteHeader-login"
                  onClick={() => setLogin(!login)}
                >
                  <div>{t("login")}</div>
                </div>
              )}
            </div>
            <div
              className="WebsiteHeader-box2"
              onClick={() => setMobDrawer(!mobDrawer)}
            >
              <AiOutlineMenu />
            </div>
          </Col>
        </Row>
      </Container>

      {login ? (
        <LoginModal open={login} close={() => setLogin(!login)} />
      ) : null}

      {mobDrawer ? (
        <SideDrawer
          open={mobDrawer}
          close={() => setMobDrawer(!mobDrawer)}
          login={() => setLogin(true)}
        />
      ) : null}
    </div>
  );
}

export default WebsiteHeader;
