import { Drawer, Input } from "antd";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./styles.scss";
import { CiSearch } from "react-icons/ci";
import { FcNeutralTrading } from "react-icons/fc";
import {
  PiFileTextLight,
  PiGiftLight,
  PiWalletBold,
  PiWalletLight,
} from "react-icons/pi";
import { MdOutlineRedeem, MdOutlineSip } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaArrowTrendUp, FaRegFileLines } from "react-icons/fa6";
import { FiGift } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function Helpa(props: any) {
  const [answerShow, setAnswerShow] = useState<any>({});

  const HelpaCategories = [
    {
      head: "Trading",
      icon: <FaArrowTrendUp size={30} color="gray" />,
      desc: "63 articles",
    },
    {
      head: "Wallet",
      icon: <PiWalletBold size={30} color="gray" />,
      desc: "15 articles",
    },
    {
      head: "KYC",
      icon: <FaRegFileLines size={30} color="gray" />,
      desc: "30 articles",
    },
    {
      head: "SIP",
      icon: <MdOutlineSip size={30} color="gray" />,
      desc: "20 articles",
    },
    {
      head: "Redeem",
      icon: <MdOutlineRedeem size={30} color="gray" />,
      desc: "15 articles",
    },
    {
      head: "Gift",
      icon: <FiGift size={30} color="gray" />,
      desc: "15 articles",
    },
  ];
  const Questions = [
    {
      Header: "Is that possible to by Gold by 10 AED?",
      desc: "Yeah sure we provide an digital platform to by Gold start with 10 AED",
    },
    {
      Header: "How do I fill my KYC details?",
      desc: "Go to Home and click on your profile there is an option for create KYC",
    },
    {
      Header: "How do i change my mobile number?",
      desc: "Go to home and click on your profile then you can see view profile  and you can change mobile number",
    },
    {
      Header: "How SIP works",
      desc: "You can set a target for the amount of gold you want to acquire within a specific duration. There are options for monthly, daily, and yearly plans.",
    },
    {
      Header: "How can I retrieve gold from this platform",
      desc: "You can terminate the job and gold amount will add you Wallet",
    },
  ];
  const handleclickTrue = (index: any) => {
    setAnswerShow((prevState: any) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const handleclickFalse = (index: any) => {
    setAnswerShow((prevState: any) => ({
      ...prevState,
      [index]: false,
    }));
  };
  return (
    <Drawer
      title="Basic Drawer"
      onClose={() => props?.onClose()}
      open={props?.open}
      placement="right"
      size="large"
      headerStyle={{ display: "none" }}
      style={{ overflowY: "scroll" }}
      bodyStyle={{ padding: "0px" }}
    >
      <div className="app-HelpSession-Modal">
        <div className="app-HelpSession">
          <div className="app-HelpSession-Text1">
            <div>How can we help?</div>
            <div onClick={() => props?.onClose()}>
              <IoCloseOutline />
            </div>
          </div>
          <br />
          <Input
            placeholder="Find an answer"
            prefix={<CiSearch color="#d1d4dc9a" />}
            size="large"
            className="app-HelpSession-Input"
          />
          <br />
          <br />
          <div className="app-HelpSession-Text1">
            Select your issue category
          </div>
          <br />
          <Row className="g-2">
            {HelpaCategories.map((item: any) => {
              return (
                <Col md={6}>
                  <div className="app-HelpSession-box">
                    <div className="app-HelpSession-box1">
                      {item.head}
                      <br />
                      <span className="app-HelpSession-txt2">{item.desc}</span>
                    </div>
                    <div className="app-HelpSession-box2">{item.icon}</div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <br />
          <div className="app-HelpSession-Text1">Frequenly asked Questions</div>
          <div className="app-HelpSession-txt2">
            Let us know how we can help
          </div>
          <br />
          {Questions?.map((items: any, index: any) => {
            return (
              <div
                className="app-HelpSession-Box1 "
                style={{ marginBottom: "30px" }}
              >
                <div className="app-HelpSession-Box3">
                  <div>
                    {items?.Header}
                    <br />
                    {answerShow[`${index}`] ? (
                      <div className="app-HelpSession-txt2">{items?.desc}</div>
                    ) : null}
                  </div>
                  {answerShow[`${index}`] ? (
                    <div>
                      <IoIosArrowUp
                        color="gray"
                        size={30}
                        onClick={() => handleclickFalse(index)}
                      />
                    </div>
                  ) : (
                    <div>
                      <IoIosArrowDown
                        color="gray"
                        size={30}
                        onClick={() => handleclickTrue(index)}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
}

export default Helpa;
