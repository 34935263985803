import React from "react";
import { Col, Row } from "react-bootstrap";

function ListItems(props: any) {
  return (
    <div className="app-HomeScreen-Section4-AppTradeList-ListItems">
      <Row className="g-2 w-100">
        <Col md={4} xs={5}>
          <div
            className="app-HomeScreen-Section4-txt3"
            style={{ color: props?.data?.type == "buy" ? "red" : "#00a56e" }}
          >
            {props?.data?.metal_type.replace("_", " ")}
          </div>
        </Col>
        <Col md={4} xs={3}>
          <div className="app-HomeScreen-Section4-txt3">
            {props?.data?.type}
          </div>
        </Col>
        <Col md={4} xs={4}>
          <div className="app-HomeScreen-Section4-txt3">
            {props?.data?.weight ? props?.data?.weight?.toFixed(2) : "-"}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ListItems;
