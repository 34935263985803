const theme: any = {
  token: {
    itemBg: "#000",
    colorPrimary: "#c99000",
  },
  components: {
    Input: {
      colorText: "#000",
    },
    InputNumber: {
      colorText: "#000",
    },
    Pagination: {
      // itemActiveBg: "#066949",
    },
    Select: {
      selectorBg: "#c99000",
    },
    Button: {
      // defaultBg: "#c99000",
      defaultColor: "#c99000 !important",
      defaultBorderColor: "#c99000",
    },
  },
};

export default theme;
