import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: false,
    user: {},
    type: null,
    authToken: "null",
    invoiceToken: "",
  },
  reducers: {
    isLoggedIn: (state, action) => {
      state.auth = true;
      state.user = action.payload;
    },
    isLoggedOut: (state) => {
      state.auth = false;
      state.user = {};
    },
    setToken: (state, action) => {
      state.authToken = action.payload;
    },
    setInvoiceToken: (state, action) => {
      state.invoiceToken = action.payload;
    },
    setVerificationTokens: (state: any, action: any): any => {
      state.verificationToken = action.payload;
    },
    updateKycStatus: (state: any, action) => {
      state.user.kyc_status = action.payload;
    },
    updateProfileImage: (state: any, action) => {
      state.user.image = action.payload.image;
      state.user.city = action.payload.city;
      state.user.email = action.payload.email;
    },
  },
});

export const {
  isLoggedIn,
  isLoggedOut,
  setToken,
  setVerificationTokens,
  updateKycStatus,
  updateProfileImage,
  setInvoiceToken,
} = authSlice.actions;

export const selectUser = (state: any) => state.auth.user;
export default authSlice.reducer;
