import moment from "moment";
import "./styles.scss";
import Dropzone from "react-dropzone";
import { Button } from "antd";
import { BsCloudUpload } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const ImageHandler = (props: any) => {
  const { t } = useTranslation();
  const handleDrop = (acceptedFiles: any) => {
    var myFile = acceptedFiles[0];
    let name = moment(new Date()).unix();
    const myNewFile = new File([myFile], name + "N.png", { type: myFile.type });
    const url = URL.createObjectURL(myNewFile);
    let obj = {
      file: myNewFile,
      url: url,
    };
    props.onChange(obj);
  };
  const getSizeClassName = () => {
    if (props?.size === "small") {
      return "small";
    } else if (props?.size === "large") {
      return "large";
    } else {
      return "";
    }
  };
  return (
    <div className="ImagePicker-ImageHandle">
      <Dropzone
        accept={{
          "image/png": [".png"],
          "image/jpeg": [".jpg"],
        }}
        onDrop={handleDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps({
                className: `ImagePicker-ImageHandler-backgroud `,
              })}
            >
              <input {...getInputProps()} />

              {props?.fileURL ? (
                <>
                  <img
                    src={props?.fileURL}
                    className="imagePickerImg"
                    alt="no image"
                  />
                  <div style={{ gap: "5px" }}>
                    <Button
                      size="small"
                      style={{ backgroundColor: "transparent", color: "#000" }}
                      onClick={() => {}}
                    >
                      {t("choose_another_image")}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <BsCloudUpload size={30} />
                  <h5 className="ImageHandlertxt2">
                    {t("drag_or_click_to_select_files")}
                  </h5>
                  <Button
                    size="small"
                    style={{ backgroundColor: "transparent", color: "#000" }}
                  >
                    {t("choose_file")}
                  </Button>
                </>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default ImageHandler;
