import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
function PageHeader(props: any) {
  const navigate = useNavigate();
  return (
    <div className="AdminRoutes-PageHeader">
      <div style={{display:"flex"}}>
      <div
        style={{ marginRight: 20, marginLeft: 10 }}
        onClick={() => navigate(-1)}
      >
        <IoArrowBackOutline size={30} color="#c99000" />
      </div>
      <div>
        <div className="AdminRoutes-PageHeadertxt1">{props?.title}</div>
        <div className="AdminRoutes-PageHeadertxt2">
          Dashboad / {props?.title}
        </div>
      </div>
      </div>
      <div style={{ flex: 1 }} />
      {props?.children ? <div>{props?.children}</div> : null}
    </div>
  );
}

export default PageHeader;
