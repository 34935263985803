import { List, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import API from "../../../../config/api";
import { GET } from "../../../../utlis/apiCalls";
import "../styles.scss";
import ListItems from "./listItems";

export default function DataTable(props: any) {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState<any>(false);
  const [sip, setSip] = useState<any>();
  const [meta, setMeta] = useState<any>();
  const [pageNo, setPageNo] = useState<any>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const { t } = useTranslation();
  useEffect(() => {
    getUserSip();
  }, [pageNo, props?.refresh]);

  const getUserSip = async () => {
    try {
      setisLoading(true);
      const response: any = await GET(
        API.SIP_USERS_LIST +
          `${props?.User?.id}?order=DESC&page=${pageNo}&take=${pageSize}`,
        null
      );
      if (response?.status) {
        setSip(response?.data?.data);
        setMeta(response?.data?.meta);
      } else {
        setSip([]);
        setMeta([]);
      }
    } catch (error) {
    } finally {
      setisLoading(false);
    }
  };

  const handlePageSize = (current: number, page: number) => {
    setPageNo(current);
    setPageSize(page);
  };

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={sip}
        bordered={false}
        loading={isLoading}
        renderItem={(item: any) => <ListItems data={item} />}
      />
      <div dir="LTR">
        <Pagination
          className="app-Sipscreen-pagination"
          current={pageNo}
          pageSize={pageSize}
          total={meta?.itemCount}
          defaultCurrent={1}
          onShowSizeChange={handlePageSize}
          responsive={true}
          defaultPageSize={pageSize}
          disabled={false}
          onChange={(page: any) => setPageNo(page)}
          nextIcon={<IoIosArrowDropright color="#ffffff" size={20} />}
          prevIcon={<IoIosArrowDropleft color="#ffffff" size={20} />}
          jumpPrevIcon={
            <MdOutlineKeyboardDoubleArrowLeft color="#ffffff" size={20} />
          }
          jumpNextIcon={
            <MdOutlineKeyboardDoubleArrowRight color="#ffffff" size={20} />
          }
        />
      </div>
    </div>
  );
}
