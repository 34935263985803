import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeader from "../../routes/pageHeader";
import { GET } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Image } from "antd";
import "./styles.scss";
import UpdateKycModal from "./components/updateKycModal";
import { LuRefreshCcw } from "react-icons/lu";
import { statusType } from "../../../shared/types/kyc";
function KycDetails() {
  const navigate = useNavigate();
  const [kycDetails, setkycDetails] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<any>();
  const param: any = useParams();
  const [kycStatusType, setKycStatusType] = useState<string>();
  const [kycBio, setKycBio] = useState<string>();
  const [isUpdateKycOpen, setIsUpdateKycOpen] = useState<boolean>(false);

  useEffect(() => {
    getkycDetails();
  }, [refresh]);
  const getkycDetails = async () => {
    try {
      setisLoading(true);
      const userId = param?.id ? atob(param.id) : "";
      const response: any = await GET(`${API.GET_KYC_BY_ID}${userId}`, null);
      if (response?.statusCode !== 404) {
        setKycStatusType(response?.isissued);
        setkycDetails(response);
      }
    } catch (error) {
      console.error("Error fetching KYC details:", error);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div>
      {/* {contextHolder} */}
      <br />
      <Container fluid={false}>
        <PageHeader title={"KYC Details"}>
          <div className="d-flex">
            <div>
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/admin/customerdetails/${atob(param?.id)}`)
                }
              >
                Customer Details
              </Button>
            </div>
            <div style={{ width: 20 }} />
            {kycStatusType === "accepted" ? (
              <>
                <div>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      // setKycStatusType(statusType.reject);
                      setKycBio(statusType.reject);

                      setIsUpdateKycOpen(true);
                    }}
                  >
                    Reject KYC
                  </Button>
                </div>
                <div style={{ width: 20 }} />
              </>
            ) : kycStatusType === "rejected" ? (
              <>
                <div>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "green" }}
                    onClick={() => {
                      // setKycStatusType(statusType.accept);
                      setKycBio(statusType.accept);

                      setIsUpdateKycOpen(true);
                    }}
                  >
                    Accept KYC
                  </Button>
                </div>
                <div style={{ width: 20 }} />
              </>
            ) : kycStatusType === "pending" ? (
              <>
                <div>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "green" }}
                    onClick={() => {
                      setKycBio(statusType.accept);

                      // setKycStatusType(statusType.accept);
                      setIsUpdateKycOpen(true);
                    }}
                  >
                    Accept KYC
                  </Button>
                </div>
                <div style={{ width: 20 }} />
                <div>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      setKycBio(statusType.reject);
                      // setKycStatusType(statusType.reject);
                      setIsUpdateKycOpen(true);
                    }}
                  >
                    Reject KYC
                  </Button>
                </div>
                <div style={{ width: 20 }} />
              </>
            ) : null}

            <div>
              <Button
                type="primary"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <LuRefreshCcw size={20} />
              </Button>
            </div>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <Card loading={isLoading}>
            <div style={{ marginLeft: 35 }}>
              <div style={{ marginTop: "20px" }}>
                <div className="admin-KycScreen-txt3">Customer Details</div>
                <Row>
                  <Col md={6}>
                    <div className="pb-1">
                      <span className="admin-KycScreen-txt8">Name :</span>{" "}
                      <span className="admin-KycScreen-txt5">
                        {kycDetails?.name}
                      </span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pb-1">
                      <span className="admin-KycScreen-txt8">Mobile :</span>{" "}
                      <span className="admin-KycScreen-txt5">
                        {kycDetails?.mobile}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="pb-1">
                      <span className="admin-KycScreen-txt8">Address :</span>{" "}
                      <span className="admin-KycScreen-txt5">
                        {kycDetails?.address}
                      </span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pb-1">
                      <span className="admin-KycScreen-txt8">Pin Code :</span>{" "}
                      <span className="admin-KycScreen-txt5">
                        {kycDetails?.pinCode}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="pb-1">
                      <span className="admin-KycScreen-txt8">Email :</span>{" "}
                      <span className="admin-KycScreen-txt5">
                        {kycDetails?.email}
                      </span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <span className="admin-KycScreen-txt8">Type : </span>
                      <span className="admin-KycScreen-txt5">
                        {kycDetails?.type}
                      </span>
                    </div>
                  </Col>
                </Row>
                {kycDetails?.type === "company" && (
                  <div>
                    <Row>
                      <Col md={6}>
                        <div className="pb-1">
                          <span className="admin-KycScreen-txt8">
                            Nature of Business :
                          </span>{" "}
                          <span className="admin-KycScreen-txt5">
                            {kycDetails?.natureOfBusiness}
                          </span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="pb-1">
                          <span className="admin-KycScreen-txt8">
                            Company Name :
                          </span>{" "}
                          <span className="admin-KycScreen-txt5">
                            {kycDetails?.companyName}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div>
                          <span className="admin-KycScreen-txt8">
                            VAT Number :
                          </span>
                          <span className="admin-KycScreen-txt5">
                            {kycDetails?.vatNumber}
                          </span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <span className="admin-KycScreen-txt8">
                            Is Logistics Service required? :
                          </span>
                          <span className="admin-KycScreen-txt5">
                            {kycDetails?.isLogisticServiceRequired
                              ? "Yes"
                              : "No"}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <hr />
              </div>

              <div className="admin-KycScreen-txt3">Proprietor/Partners</div>
              <Row>
                <Col md={6}>
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">
                      Name (primary) :
                    </span>
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.vatNumber}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">
                      Mobile Number (primary) :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.primaryPartnerMobile}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {" "}
                  <div>
                    <span className="admin-KycScreen-txt8">
                      Name (Secondary) :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.secondaryPartnerName}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  {" "}
                  <div>
                    <span className="admin-KycScreen-txt8">
                      Mobile Number (Secondary) :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.secondaryPartnerMobile}
                    </span>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="admin-KycScreen-txt3">Bank Details</div>
              <Row>
                <Col md={6}>
                  {" "}
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">Bank Name :</span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.bankName}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">
                      Bank Account Name :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.bankAccountName}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {" "}
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">
                      Account Number :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.accountNumber}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">IFSC Code :</span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.ifscCode}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {" "}
                  <div>
                    <span className="admin-KycScreen-txt8">Branch:</span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.branch}
                    </span>
                  </div>
                </Col>
                <Col md={6}></Col>
              </Row>
              <hr />
              <div className="admin-KycScreen-txt3">
                Persons Authorized for Delivery
              </div>
              <Row>
                <Col md={6}>
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">
                      Person Name (Primary) :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.authorisedPersonPrimary}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pb-1">
                    <span className="admin-KycScreen-txt8">
                      Person Name (Secondary) :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.authorisedPersonSecondary}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {" "}
                  <div>
                    <span className="admin-KycScreen-txt8">
                      Person Name (Tertiary) :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.authorisedPersonTertiary}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  {" "}
                  <div>
                    <span className="admin-KycScreen-txt8">
                      Person Name (Quaternary) :
                    </span>{" "}
                    <span className="admin-KycScreen-txt5">
                      {kycDetails?.authorisedPersonQuaternary}
                    </span>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="admin-KycScreen-txt3">Uploaded Documents</div>
              {kycDetails?.type != "company" ? (
                <Row>
                  <Col md={6}>
                    <div className="pb-1">
                      <span className="admin-KycScreen-txt8">
                        Govt Issued ID Type :{" "}
                        <span style={{ color: "black" }}>
                          {kycDetails?.city?.toUpperCase()}
                        </span>
                      </span>{" "}
                      <span className="admin-KycScreen-txt5">
                        {kycDetails?.authorisedPersonPrimary}
                      </span>
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row>
                {kycDetails?.type != "company" ? (
                  <Col md={6}>
                    {" "}
                    <div className="pb-2">
                      <span className="admin-KycScreen-txt8">
                        {kycDetails?.city}
                      </span>{" "}
                      <div className="pt-3 pb-3">
                        <Image
                          width={"80%"}
                          src={kycDetails?.authorisedPersonPrimaryImage}
                          alt="user Uploaded image incorrectly"
                        />
                      </div>
                    </div>
                  </Col>
                ) : null}
                {kycDetails?.type === "company" ? (
                  <Col md={6}>
                    {" "}
                    <div className="pb-2">
                      <span className="admin-KycScreen-txt8">
                        Company Registration Certificate
                      </span>{" "}
                      <div className="pt-3 pb-3">
                        <Image
                          width={"80%"}
                          src={kycDetails?.vatCertificate}
                          alt="user Uploaded image incorrectly"
                        />
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col md={6}>
                    {" "}
                    <div className="pb-2">
                      <span className="admin-KycScreen-txt8">
                        ID Document or Camera Photo
                      </span>{" "}
                      <div className="pt-3 pb-3">
                        <Image
                          width={"80%"}
                          src={kycDetails?.authorisedPersonSecondaryImage}
                          alt="user Uploaded image incorrectly"
                        />
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </Card>
        </div>
      </Container>
      <UpdateKycModal
        userId={atob(param?.id)}
        isOpen={isUpdateKycOpen}
        type={kycBio}
        close={() => setIsUpdateKycOpen(false)}
        refreshKyc={() => setRefresh(!refresh)}
      />
    </div>
  );
}

export default KycDetails;
