import { Button } from "antd";
import { useState } from "react";
import "./styles.scss";

import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AppPageHeader from "../../routes/appPageHeader";
import DataTable from "./components/dataTable";
import HowItWorks from "./components/howItWorkSection";
import IntroductionSection from "./components/introductionSection";
import SipCalculator from "./components/sipCalculator";

function SipScreen() {
  const { t } = useTranslation();
  const liveRate = useSelector((state: any) => state.liveRate.current);
  const currentInfo = useSelector((state: any) => state?.currencyInfo?.current);
  const User = useSelector((state: any) => state?.auth?.user);

  const [refresh, setRefresh] = useState(false);

  const changeRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <div>
      <Container>
        <div className="app-SIPScreen">
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "20px",
            }}
          >
            <div className="app-SIPScreen-box1"></div>
            <div className="app-SIPScreen-txt1">{t("my_sip")}</div>
            <div style={{ flex: 1 }}></div>
            <div style={{ display: "flex", gap: "2px" }}>
              <a href="#sipTable">
                <Button className="app-SIPScreen-Buttons">
                  {t("view_sip")}
                </Button>
              </a>
              <div style={{ width: 20 }} />
              <a href="#sipCalculator">
                <Button className="app-SIPScreen-Buttons">
                  {t("create_sip")}
                </Button>
              </a>
            </div>
          </div>
          <br />
          <br />
          <IntroductionSection />
          <br />
          <HowItWorks />
          <br />
          <SipCalculator
            User={User}
            liveRate={liveRate}
            currentInfo={currentInfo}
            changeRefresh={changeRefresh}
          />
          <br />
          <div>
            <br />
            <div className="app-SipScreen-txt4">{t("my")} SIP</div>
            <br />
            <DataTable User={User} refresh={refresh} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SipScreen;
