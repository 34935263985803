import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  InteractionModeMap,
} from "chart.js";
import "chart.js/auto";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const Utils = {
  numbers: ({
    count,
    min,
    max,
  }: {
    count: number;
    min: number;
    max: number;
  }) => {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(Math.floor(Math.random() * (max - min + 1)) + min);
    }
    return arr;
  },
  months: ({ count }: { count: number }) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
    ];
    return months.slice(0, count);
  },
  CHART_COLORS: {
    green: "#00a56e",
    red: "#f44336",
  },
};

const DATA_COUNT = 7;
const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

const labels = Utils.months({ count: 7 });

const data = {
  labels: labels,
  datasets: [
    {
      label: "Inflow",
      data: Utils.numbers(NUMBER_CFG),
      borderColor: Utils.CHART_COLORS.green,
      backgroundColor: Utils.CHART_COLORS.green,
      fill: false,
      tension: 0.3, // Smooth the line a bit
      pointRadius: 5,
      pointBackgroundColor: Utils.CHART_COLORS.green,
    },
    {
      label: "Outflow",
      data: Utils.numbers(NUMBER_CFG),
      borderColor: Utils.CHART_COLORS.red,
      backgroundColor: Utils.CHART_COLORS.red,
      fill: false,
      tension: 0.3, // Smooth the line a bit
      pointRadius: 5,
      pointBackgroundColor: Utils.CHART_COLORS.red,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Wallet Flow Over Time",
      font: {
        size: 18,
      },
    },
    tooltip: {
      mode: "index" as keyof InteractionModeMap,
      intersect: false,
    },
    legend: {
      display: true,
      position: "top" as const,
    },
  },
  interaction: {
    mode: "nearest" as keyof InteractionModeMap,
    intersect: false,
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Month",
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "Amount ($)",
      },
      beginAtZero: true,
    },
  },
};

const LineChart: React.FC = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
