import { Input, Pagination, message } from "antd";
import { useEffect, useState } from "react";
import API from "../../../config/api";
import DataTable from "./components/dataTable";
import { Container } from "react-bootstrap";
import PageHeader from "../../routes/pageHeader";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import useDebounce from "../../../shared/helpers/useDebounce";

function KycVerify() {
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const authToken: any = useSelector((state: any) => state?.auth?.authToken);
  useEffect(() => {
    getAllKycUploaders();
  }, [useDebounce(searchValue, 1500), page, take]);

  const getAllKycUploaders = async () => {
    try {
      setIsLoading(true);
      let url =
        API.BASE_URL +
        API.USER_KYC_UPLOADED +
        `?order=DESC&page=${page}&take=${take}&search=${searchValue}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(url, options);
      if (response.status === 200) {
        const data = await response.json();
        setData(data.data);
        setMeta(data.meta);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const pagination = (page: any, take: any) => {
    window.scrollTo(0, 0);
    setPage(page);
    setTake(take);
  };
  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };
  const handlePageSize = (current: number, page: number) => {
    setPage(current);
    setTake(page);
  };
  return (
    <Container fluid={false}>
      <br />
      <PageHeader title={"Kyc Verify"}>
        <Input
          style={{ width: "200px" }}
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          prefix={<IoSearchOutline size={20} color="gray" />}
          placeholder="Search here.."
          suffix={
            <IoIosClose
              size={20}
              color="grey"
              cursor={"pointer"}
              onClick={() => setSearchValue("")}
            />
          }
        />
      </PageHeader>
      <div className="overFlow-Box1">
        <DataTable data={data} isLoading={isLoading} />
        {data && (
          <Pagination
            defaultCurrent={meta?.page}
            total={meta?.itemCount}
            pageSize={meta?.take ? meta?.take : 0}
            pageSizeOptions={[10, 20, 50]}
            onShowSizeChange={handlePageSize}
            onChange={(page, take) => pagination(page, take)}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        )}
      </div>
    </Container>
  );
}

export default KycVerify;
