import { useSelector } from "react-redux";
import { metalType } from "../types/metalTypes";
import axios from "axios";
import API from "../../config/api";

// pick color for status
export const StatusColor = (status: string) => {
  if (status === "accepted") {
    return "green";
  } else if (status === "pending") {
    return "#00fffdab";
  } else if (status === "rejected" || status === "cancelled") {
    return "red";
  } else if (status === "accept" || status === "cancelled") {
    return "green";
  } else {
    return "red";
  }
};

// pick color for type (buy or sell)
export const TypeColor = (type: string) => {
  if (type === "buy") {
    return "#009688";
  } else {
    return "#9C27B0";
  }
};

// pick color for metal type
export const metalColor = (metalType: string) => {
  if (metalType === "gold_995") {
    return "#DFAD30";
  } else if (metalType === "gold_9999") {
    return "#c99000";
  } else if (metalType === "silver_999") {
    return "silver";
  } else if (metalType === "cash") {
    return "#979797";
  } else {
    return "gold";
  }
};

export const getMetal = (val: string) => {
  switch (val) {
    case "gold_995":
      return "GOLD 995";
    case "gold_9999":
      return "GOLD 9999";
    case "silver_999":
      return "SILVER 999";
    default:
      return "";
  }
};

export const metalNameConversion = (data: any) => {
  switch (data) {
    case "GOLD 995":
      return "gold_995";
      break;
    case "GOLD 9999":
      return "gold_9999";
      break;
    case "SILVER 999":
      return "silver_999";
      break;
    default:
      return "";
  }
};

export function capitalizeAllWords(text: string): string {
  // Check if the input text is not empty
  if (!text) {
    return "";
  }
  const words = text.split(" ");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
}

//changes text "gold_999" to "Gold 999"
export const changeMetalTypeFormat = (originalText: string) => {
  const parts = originalText?.split("_");
  const capitalizedParts = parts?.map(
    (part) => part?.charAt(0)?.toUpperCase() + part?.slice(1)
  );
  return capitalizedParts?.join(" ");
};

export const handleLiveRateByType = (type: string, metalData: any) => {
  type = type.toLowerCase();
  if (type === "buy") {
    return metalData?.totalBuyAmount;
  } else if (type === "sell") {
    return metalData?.totalSellAmount;
  }
};

export const metaLiveRate = (text: metalType, liveRate: any) => {
  const metalLiveRate = liveRate?.current?.find(
    (item: any) => item.metal === changeMetalTypeFormat(text).toUpperCase()
  );
  if (metalLiveRate) {
    return metalLiveRate?.totalSellAmount;
  }
  return 0;
};
export const totalCashFromMetal = (wallet: any, liveRate: any) => {
  let total: number = 0;
  wallet?.map((item: any) => {
    if (item?.metal_type === metalType.cash) {
      total = total + item?.balance;
    } else {
      total = total + item?.balance * metaLiveRate(item?.metal_type, liveRate);
    }
  });
  return total;
};

export const fetchUserCountry = async (currencyInfo: any) => {
  try {
    const geolocationUrl = `https://www.googleapis.com/geolocation/v1/geolocate?key=${API.GGL_TOKEN}`;

    const geolocationResponse = await axios.post(geolocationUrl);
    const { location } = geolocationResponse.data;
    const { lat, lng } = location;
    const reverseGeocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API.GGL_TOKEN}`;
    const reverseGeocodeResponse = await axios.get(reverseGeocodeUrl);

    const countryComponent = reverseGeocodeResponse.data.results.find(
      (result: any) => result.types.includes("country")
    );

    if (countryComponent) {
      const countryObject = countryComponent.address_components.find(
        (component: any) => component.types.includes("country")
      );

      if (countryObject) {
        const countryCode = countryObject.short_name;
        let info = currencyInfo.find(
          (result: any) => result.country_code === countryCode
        );
        return info?.dail_code;
      } else {
        console.error("Country code not found.");
      }
    } else {
      console.error("Country component not found.");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getInterval = (data: any) => {
  switch (data) {
    case 0:
      return "daily";
      break;
    case 33:
      return "weekly";
      break;
    case 66:
      return "monthly";
      break;
    case 100:
      return "yearly";
      break;
    default:
      return "";
  }
};
