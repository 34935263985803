import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import frame1 from "../../../assets/images/Frame-1.svg";
import frame2 from "../../../assets/images/Frame-2.svg";
import frame3 from "../../../assets/images/Frame-3.svg";
import frame4 from "../../../assets/images/Frame-4.svg";
import frame5 from "../../../assets/images/Frame-5.svg";
import frame6 from "../../../assets/images/Frame-6.svg";
import frame7 from "../../../assets/images/Frame-7.svg";
import frame8 from "../../../assets/images/Frame-8.svg";
import frame9 from "../../../assets/images/Frame-9.svg";
import frame10 from "../../../assets/images/Frame-10.svg";
import frame11 from "../../../assets/images/Frame-11.svg";
import frame12 from "../../../assets/images/Frame-12.svg";

function Section1() {
  const cards = [
    {
      head: "Ownership Mindset",
      dis: "Take accountability for the quality and success of the outcome of your work.",
      icon: frame1,
    },
    {
      head: "Quality over quantity",
      dis: "It is fine if you pick up a few things to do, but do them exceedingly well.",
      icon: frame2,
    },
    {
      head: "Less words, more action",
      dis: "Believe in something? show it by doing things. Not just by talking about it.",
      icon: frame3,
    },
    {
      head: "Entrepreneurial Mindset",
      dis: "Be disciplined and have skin in the game.",
      icon: frame4,
    },
    {
      head: "Equal opportunity employer",
      dis: "We celebrate diversity and inclusion.",
      icon: frame5,
    },
    {
      head: "Passionate about the business",
      dis: "Do it because you are passionate about it, not because it's your job.",
      icon: frame6,
    },
    {
      head: "Open culture & conversations",
      dis: "You are among all of us, and we all are with you.",
      icon: frame7,
    },
    {
      head: "Talent and Competency",
      dis: "We believe in skills and competency, over certifications. We learned it all the hard way with life & experiences.",
      icon: frame8,
    },
    {
      head: "Be obsessed with customer",
      dis: "We trust you to help us gain and retain the trust of our customers and our stakeholders.",
      icon: frame9,
    },
    {
      head: "Speed matters",
      dis: "You either help the team move ahead fast, or you slow it down. We prefer the first.",
      icon: frame10,
    },
    {
      head: "Respect everyone and everything",
      dis: "Respect your work, your team, your co-workers and our customers. The only way to get respect is to give it first.",
      icon: frame11,
    },
    {
      head: "Be Humble",
      dis: "If you are the smartest one in the room, bring up others to speed. We are here to serve our customers, not to prove a point.",
      icon: frame12,
    },
  ];
  return (
    <div className="aboutScreen-section1">
      <Container>
        <Fade>
          <div className="homeScreen-text10 center">
            Our <span className="homeScreen-text2">Values and Culture</span>
          </div>
          <div className="homeScreen-text11 center">
            People and culture are the most important aspects of our business.
            <br /> We are looking for team members just like us,in simple words
            - good people
            <br /> to work with and demonstrate following personality traits:
          </div>
        </Fade>
        <br />
        <br />
        <Row>
          {cards.map((item: any, index: any) => {
            return (
              <Col sm={4} xs={12}>
                <Fade delay={100} style={{ height: "100%" }}>
                  <div className="aboutScreen-section1-box1">
                    <div>
                      <img src={item?.icon} />
                    </div>
                    <div style={{ flex: 1 }}>
                      <div style={{ marginBottom: 10 }}>{item?.head}</div>
                      <div className="homeScreen-text11">{item?.dis}</div>
                    </div>
                  </div>
                </Fade>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Section1;
