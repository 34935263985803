import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  message,
  notification,
} from "antd";
import { InputOTP } from "antd-input-otp";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { fetchUserCountry } from "../../../../shared/helpers/functions";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoggedIn,
  setVerificationTokens,
} from "../../../../Redux/slice/authSlice";

import API from "../../../../config/api";
import { POST, PUT } from "../../../../utlis/apiCalls";

export default function EditModal(props: any) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [t] = useTranslation();

  const User = useSelector((state: any) => state.auth.user);
  const currencyInfo = useSelector(
    (state: any) => state.currencyInfo.currencyInfo
  );
  const verificationToken = useSelector(
    (state: any) => state.auth.verificationToken
  );

  const [notificationAPi, contextHolder] = notification.useNotification();
  const num = User?.phoneNumber?.split(" ");

  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [verify, setVerify] = useState(false);

  const [data, setData] = useState<any>({});
  const [otpTimer, setOtpTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    let intervalId: any;
    // Start the countdown timer if the OTP modal is visible and the timer is greater than 0
    if (verify && otpTimer > 0) {
      intervalId = setInterval(() => {
        setOtpTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }
    // Clear the interval when the component unmounts or when the OTP modal is closed
    return () => clearInterval(intervalId);
  }, [verify, otpTimer]);

  useEffect(() => {
    fetchUserCountry(currencyInfo)
      .then((res: any) => {
        setDefaultCountryCode(res);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    if (defaultCountryCode) {
      form.setFieldsValue({ code: defaultCountryCode });
    }
  }, [defaultCountryCode, form]);

  const onFinish = async (val: any) => {
    setIsLoading(true);
    let url = val?.phoneNumber
      ? API.UPDATE_USER_PHONE + User?.id
      : API.USER_EDIT + User?.id;
    let body = {};
    if (val?.phoneNumber) {
      body = {
        phoneNumber: `+${val?.code} ${val?.phoneNumber}`,
      };
    } else {
      body = val;
    }

    setData(val);
    try {
      const response: any = await PUT(url, body);
      if (response?.data?.id) {
        dispatch(isLoggedIn(response?.data));
        props?.onClose();
        notificationAPi.success({
          message: t("profile_updated_successfully"),
        });
        setIsLoading(false);
      } else if (response.statusOTP) {
        message.success("OTP sented to your whatsapp");
        dispatch(setVerificationTokens(response?.token));
        setVerify(true);
        setIsLoading(false);
      } else if (response.statusexistingUser) {
        notificationAPi.error({
          message: "These Number Is Already Existing !!! Choose Another",
        });
        setIsLoading(false);
      }
    } catch (error) {
      notificationAPi.error({
        message: "Something Went Wrong",
      });
      setIsLoading(false);
    }
  };

  const verifyOtp = async (val: any) => {
    try {
      setIsLoading(true);
      let joinVal: string = val?.otp?.join("");

      let reqBody = {
        token: verificationToken,
        otp: joinVal,
        phoneNumber: `+${data?.code} ${data?.phoneNumber}`,
        id: User?.id,
      };
      let res: any = await POST(API.UPDATE_PHONE_REQUEST_OTP, reqBody);
      console.log(res, "res");

      if (res?.userId) {
        dispatch(isLoggedIn(res?.userId?.data));
        props?.onClose();
        message.success("Verified Successfully");
        setVerify(false);
      } else {
        message.error(res?.userId?.message);
      }
    } catch (err: any) {
      setIsLoading(false);
      console.log("Error Otp", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = () => {
    try {
      onFinish(data);
      setOtpTimer(60);
      form.resetFields(["otp"]);
      setResendDisabled(true);
      setTimeout(() => {
        setResendDisabled(false);
      }, 60000);
    } catch (err) {
      return err;
    }
  };
  const selectBefore = (
    <Form.Item
      name="code"
      initialValue={num?.[0]}
      rules={[{ required: true, message: "required" }]}
      style={{ margin: 0, height: "5vh" }}
    >
      <Select
        style={{ width: "110px", color: "white" }}
        size="large"
        bordered={false}
        defaultActiveFirstOption={true}
        value={defaultCountryCode}
      >
        {currencyInfo.map((item: any, index: number) => (
          <Select.Option key={index} value={item?.dail_code}>
            <img
              src={`https://flagsapi.com/${item?.country_code}/flat/24.png`}
            />
            &nbsp; +{item?.dail_code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
  return (
    <Modal
      open={props?.isModalOpen}
      onCancel={() => {
        props?.onClose();
        setVerify(false);
      }}
      footer={false}
      className="app-ProfileScreen-Section2-UpdateModal"
      width={500}
    >
      <br />
      <div className="app-ProfileScreen-txt6">{t("edit_profile")}</div>
      <br />
      {contextHolder}
      <Form
        onFinish={verify ? verifyOtp : onFinish}
        layout="vertical"
        form={form}
      >
        {props?.type === "name" ? (
          <Form.Item
            initialValue={User?.name}
            name={"name"}
            label={
              <label className="app-ProfileScreen-txt7 dark:app-ProfileScreen-txt7-dark">
                {t("name")}{" "}
              </label>
            }
          >
            <Input placeholder={t("name")} />
          </Form.Item>
        ) : (
          ""
        )}
        {props?.type === "email" ? (
          <Form.Item
            initialValue={User?.email}
            name={"email"}
            label={
              <label className="app-ProfileScreen-txt7 dark:app-ProfileScreen-txt7-dark ">
                {t("email")}{" "}
              </label>
            }
            rules={[
              {
                type: "email",
                message: t("please_enter_valid_email"),
              },
            ]}
          >
            <Input placeholder={t("email")} type="email" />
          </Form.Item>
        ) : (
          ""
        )}
        {props?.type === "city" ? (
          <Form.Item
            initialValue={User?.city}
            name={"city"}
            label={
              <label className="app-ProfileScreen-txt7 dark:app-ProfileScreen-txt7-dark">
                {t("city")}{" "}
              </label>
            }
            rules={[
              {
                pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                message: t("please_enter_valid_city_name"),
              },
            ]}
          >
            <Input placeholder={t("city")} />
          </Form.Item>
        ) : (
          ""
        )}
        {props?.type === "phone" ? (
          <>
            {verify ? (
              <Form.Item
                name="otp"
                rules={[{ required: true, message: "required" }]}
              >
                <InputOTP
                  inputStyle={{
                    backgroundColor: "transparent",
                    color: "#fff",
                  }}
                  autoSubmit={form}
                  inputType="numeric"
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  name="phoneNumber"
                  initialValue={num[1]}
                  rules={[
                    { required: true, message: "required" },
                    {
                      pattern: /^[0-9]*$/, // Only allows numeric input
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input
                    style={{
                      backgroundColor: "transparent",
                      color: "#fff",
                    }}
                    addonBefore={selectBefore}
                    placeholder="Enter Phone Number"
                    size="large"
                  />
                </Form.Item>
              </>
            )}
            <div id="recaptcha"></div>
            <Row>
              <Col sm={6} xs={12}>
                {verify ? (
                  <Button
                    type="text"
                    className="loginScreen-otp-btn"
                    disabled={otpTimer > 0}
                    onClick={handleResendOtp}
                  >
                    Resend OTP {otpTimer > 0 ? `in ${otpTimer}` : null}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </Form>
      <div className="app-ProfileScreen-EditBox2">
        <Button
          className="app-ProfileScreen-EditButtons cancel"
          onClick={() => {
            props?.onClose();
            setVerify(false);
          }}
        >
          cancel
        </Button>
        <Button
          className="app-ProfileScreen-EditButtons"
          onClick={() => form.submit()}
          loading={isLoading}
        >
          {verify ? "Verify" : t("update")}
        </Button>
      </div>
    </Modal>
  );
}
