import { List, message } from "antd";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import API from "../../../../config/api";
import { GET } from "../../../../utlis/apiCalls";
import LogItems from "./listItems";
import { useNavigate } from "react-router-dom";

function Section2() {
  const User = useSelector((state: any) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    getTransaction();
  }, []);

  const getTransaction = async () => {
    try {
      setIsLoading(true);
      let url = API.TRANSACTION_LOG_ALL + User?.id;
      let query = `?order=DESC&page=1&take=4`;
      const response: any = await GET(url + query, null);
      if (response?.status) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      message.error("oops. something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="app-WalletScreen-Section2">
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <div className="app-WalletScreen-Section1-text1">
          Transactions and Invoices
        </div>
        <div style={{ flex: 1 }}></div>
        <div
          className="app-WalletScreen-Section2-Box2"
          onClick={() => navigate(`/auth/transactionLog`)}
        >
          Go to Page
        </div>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        bordered={false}
        renderItem={(item: any, index) => <LogItems item={item} />}
        loading={isLoading}
      />
    </div>
  );
}

export default Section2;
