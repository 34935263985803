import React, { useState } from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Popover } from "antd";
import Clock from "react-live-clock";

import Logo from "../../assets/images/logo-2.png";

import { IoMdNotifications, IoIosHelpCircle } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";
import { LuUserCircle } from "react-icons/lu";

import LanguageSelector from "../../components/websiteHeader/languageSelector";
import ProfilePopover from "../../components/LoginModal/profilePopover";
import NotificationPopover from "./components/notificationPopover";
import MobileDrawer from "./mobileDrawer";
import Helpa from "../screens/helpSession";

function AppHeader() {
  const User = useSelector((state: any) => state.auth);
  const [mobDrawer, setMobDrawer] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  return (
    <div className="app-AppHeader">
      <Container fluid>
        <Row>
          <Col sm={12} xs={12}>
            <div className="app-AppHeader-Box">
              <div className="app-AppHeader-Mobile-menu">
                <AiOutlineMenu size={30} onClick={() => setMobDrawer(true)} />
              </div>
              <div className="app-AppHeader-Mobile-logo">
                <img src={Logo} />
                <div>
                  LSG <span className="app-sideBar-text1">Digital Gold</span>
                </div>
              </div>
              <div className="app-AppHeader-timer">
                <Clock format={"h:mm:ss A"} ticking={true} />
              </div>
              <div className="app-AppHeader-Box3">
                <LanguageSelector color={"#ffffffee"} />
              </div>

              <div className="app-AppHeader-Box2">
                <Popover
                  arrow={false}
                  trigger="click"
                  overlayInnerStyle={{ padding: 0, margin: 0 }}
                  content={<NotificationPopover data={User?.user} />}
                >
                  <IoMdNotifications size={25} />
                </Popover>
              </div>
              <div
                className="app-AppHeader-Box2"
                onClick={() => setHelpModalOpen(true)}
              >
                <IoIosHelpCircle size={25} />
              </div>

              <div>
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  arrow={false}
                  content={<ProfilePopover data={User?.user} />}
                  overlayInnerStyle={{ padding: 0, margin: 0 }}
                >
                  <div className="app-AppHeader-profile">
                    {User?.user?.image ? (
                      <img src={User?.user?.image} />
                    ) : (
                      <LuUserCircle />
                    )}
                  </div>
                </Popover>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {mobDrawer ? (
        <MobileDrawer open={mobDrawer} close={() => setMobDrawer(!mobDrawer)} />
      ) : null}
      {helpModalOpen ? (
        <Helpa
          open={helpModalOpen}
          onClose={() => setHelpModalOpen(!helpModalOpen)}
        />
      ) : null}
    </div>
  );
}

export default AppHeader;
