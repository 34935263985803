import React, { useState, useEffect } from "react";
import moment from "moment";
import Marquee from "react-fast-marquee";
import "./styles.scss";

const MarqueeComponent = () => {
  const [message, setMessage] = useState("");

  const calculateTimeLeft = () => {
    const now = moment();
    const nextMonday = moment().day(8).startOf("day");
    const duration = moment.duration(nextMonday.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
  };

  useEffect(() => {
    const updateMessage = () => {
      const today = moment().day();
      const timeLeft = calculateTimeLeft();

      if (today === 6) {
        setMessage(
          `The market is currently closed. It will reopen in one day. Time remaining until market opens: ${timeLeft}.`
        );
      } else if (today === 0) {
        setMessage(
          `The market is currently closed. It will reopen tomorrow. Time remaining until market opens: ${timeLeft}.`
        );
      }
    };

    updateMessage();
    const interval = setInterval(updateMessage, 1000);

    return () => clearInterval(interval);
  }, []);

  const isWeekend = () => {
    const today = moment().day();
    return today === 6 || today === 0;
  };

  if (!isWeekend()) {
    return null;
  }

  return (
    <div className="marquee-container">
      <Marquee gradient={false}>
        <span>{message}</span>
      </Marquee>
    </div>
  );
};

export default MarqueeComponent;
