import { Card } from "antd";
import { Col, Row } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { useSelector } from "react-redux";
import "../styles.scss";
export default function LiveRateCard() {
  const liveRate = useSelector((state: any) => state.liveRate);
  const findMetalRateChange = (metal: string, type: string) => {
    const current = liveRate?.current?.find(
      (item: any) => item?.metal === metal
    );
    const previous = liveRate?.prev?.find((item: any) => item?.metal === metal);
    if (type === "buy") {
      return current?.totalBuyAmount - previous?.totalBuyAmount;
    } else {
      return current?.totalSellAmount - previous?.totalSellAmount;
    }
  };
  return (
    <Card className="admin-Dashboard-card1">
      <div className="Dashboard-txt3" style={{ marginBottom: 10 }}>
        Buy Rate
      </div>
      <Row>
        {liveRate?.current.map((item: any) => (
          <Col sm={4}>
            <Card className="admin-Dashboard-card2">
              <div className="d-flex justify-content-between">
                <div className="Dashboard-txt1">{item?.metal}</div>
                <div className="Dashboard-txt4">{item?.variationBuyRate} </div>
              </div>
              <br />
              <div className="Dashboard-row">
                <div
                  className="Dashboard-txt2"
                  style={{
                    color:
                      findMetalRateChange(item?.metal, "buy") >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {item?.totalBuyAmount?.toFixed(2)}
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    {findMetalRateChange(item?.metal, "buy") >= 0 ? (
                      <FaArrowUp color="green" size={16} />
                    ) : (
                      <FaArrowDown color="red" size={16} />
                    )}
                  </div>
                  <div style={{ width: 4 }} />
                  <div className="Dashboard-txt4">
                    {findMetalRateChange(item?.metal, "buy").toFixed(2)}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
        {/* <Col sm={4}>
          <Card>
            <div className="Dashboard-txt1">GOLD SPOT</div>
            <br />
            <div className="Dashboard-row">
              <div className="Dashboard-txt2">264.75 AED</div>
              <div>10 </div>
            </div>
          </Card>
          <br />
        </Col>
        <Col sm={4}>
          <Card>
            <div className="Dashboard-txt1">SILVER SPOT</div>
            <br />
            <div className="Dashboard-row">
              <div className="Dashboard-txt2">264.75 AED</div>
              <div>10 </div>
            </div>
          </Card>
          <br />
        </Col> */}
      </Row>
      <br></br>
      <div className="Dashboard-txt3" style={{ marginBottom: 10 }}>
        Sell Rate
      </div>
      <Row>
        {liveRate?.current.map((item: any) => (
          <Col sm={4}>
            <Card className="admin-Dashboard-card2">
              <div className="d-flex justify-content-between">
                <div className="Dashboard-txt1">{item?.metal}</div>
                <div className="Dashboard-txt4">{item?.variationSellRate} </div>
              </div>
              <br />
              <div className="Dashboard-row">
                <div
                  className="Dashboard-txt2"
                  style={{
                    color:
                      findMetalRateChange(item?.metal, "sell") >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {item?.totalSellAmount?.toFixed(2)}
                </div>
                <div className="d-flex align-items-center">
                  <div>
                    {findMetalRateChange(item?.metal, "sell") >= 0 ? (
                      <FaArrowUp color="green" size={16} />
                    ) : (
                      <FaArrowDown color="red" size={16} />
                    )}
                  </div>
                  <div style={{ width: 4 }} />
                  <div className="Dashboard-txt4">
                    {findMetalRateChange(item?.metal, "sell").toFixed(2)}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
}
