import AppTradeList from "./appTradeList";

function Section4(props: any) {
  return (
    <div className="app-HomeScreen-Section4">
      <div className="app-HomeScreen-Section4-txt2">
        Trade history &nbsp;
        <span className="app-HomeScreen-Section4-txt1">/Buy and Sell</span>
      </div>
      <br />
      <AppTradeList data={props?.data} />
      <br />
    </div>
  );
}

export default Section4;
