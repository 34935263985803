import { ConfigProvider, Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import Header from "./header";
import SideBar from "./sideBar";
import "./styles.scss";
import Customers from "../screens/customers";
import Dashboard from "../screens/dashboard";
import KycVerify from "../screens/kycVerification";
import Script from "../screens/script";
import Enquiries from "../screens/Enquiries";
import Trading from "../screens/trading";
import TradingDetails from "../screens/trading/details";
import CustomerDetails from "../screens/customers/details";
import CurrencyExchange from "../screens/currencyExhange";
import RedeemRequest from "../screens/redeemRequest";
import RedeemDetails from "../screens/redeemRequest/details";
import KycDetails from "../screens/kycVerification/details";
import Sip from "../screens/sip";
import SipDetails from "../screens/sip/details";
import theme from "../config/theme";
import Wallet from "../screens/wallet";
import WalletDetails from "../screens/wallet/components/details";

const { Sider } = Layout;
function AdminRoutes() {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const authToken: any = useSelector((state: any) => state?.auth?.authToken);
  // if (authToken) {
  //   const decoded: any = jwtDecode(authToken);
  //   let currentDate = new Date();
  //   if (decoded.exp && decoded.exp * 1000 < currentDate.getTime()) {
  //     dispatch(isLoggedOut());
  //     navigate("/");
  //   }
  // }
  return (
    <ConfigProvider theme={theme}>
      <Layout className="AdminRoutes-container">
        <Sider className="AdminRoutes-Sider" width={110}>
          <SideBar />
        </Sider>
        <Layout className="AdminRoutes-layout" style={{ marginLeft: 110 }}>
          <Header />
          <div>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />;
              <Route path="/currency" element={<CurrencyExchange />} />;
              <Route path="/redeemrequest" element={<RedeemRequest />} />;
              <Route path="/redeemdetails/:id" element={<RedeemDetails />} />;
              <Route path="/trading" element={<Trading />} />;
              <Route path="/sip" element={<Sip />} />;
              <Route path="/sipDetails/:id" element={<SipDetails />} />;
              <Route path="/trddetails/:id" element={<TradingDetails />} />
              <Route path="/wallet" element={<Wallet />} />;
              <Route path="/walletDetails/:id" element={<WalletDetails />} />;
              <Route path="/customer" element={<Customers />} />;
              <Route
                path="/customerdetails/:id"
                element={<CustomerDetails />}
              />
              ;
              <Route path="/kycVerification" element={<KycVerify />} />;
              <Route path="/kycdetails/:id" element={<KycDetails />} />;
              <Route path="/script" element={<Script />} />;
              <Route path="/enquiries" element={<Enquiries />} />;
            </Routes>
          </div>
        </Layout>
      </Layout>
      </ConfigProvider>

  );
}

export default AdminRoutes;
