import { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  changeMetalTypeFormat,
  getMetal,
  handleLiveRateByType,
} from "../../../shared/helpers/functions";

import { useForm } from "antd/es/form/Form";
import { BsBagCheck } from "react-icons/bs";
import { MdOutlineSell } from "react-icons/md";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import { Row, Col } from "react-bootstrap";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { GET } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import TradeSummary from "../../../components/tradeConfirmation";
import KycStatusInfo from "../../../components/kycInformation";
import UserNotAvailable from "./userNotFound";
import { metalType } from "../../../shared/types/metalTypes";

export default function Forms(props?: any) {
  const [form] = useForm();
  const { t } = useTranslation();
  const [isFlipped, setIsFlipped] = useState(false);
  const [metal_type, setMetalType] = useState("gold_995");
  const [walletData, setWalletData] = useState<any>();
  const [walletCash, setWalletCash] = useState<any>();

  const [modalInfo, setModalInfo] = useState(false);
  const [kycModal, setKYCModal] = useState(false);
  const [userNotAvailable, setUserNotAvailable] = useState(false);

  const [kycStatus, setKycStatus] = useState<any>();

  useEffect(() => {
    let val = form.getFieldsValue();
    onChange(val);
  }, [props?.liveRate?.current]);

  useEffect(() => {
    fetchWallet();
    getKycDetails();
  }, []);

  const fetchWallet = async () => {
    try {
      const response: any = await GET(API.GET_WALLET + props?.User?.id, null);
      if (response?.status) {
        setWalletData(response?.data);
        const cash = response?.data?.find(
          (item: any) => metalType.cash === item?.metal_type
        );
        setWalletCash(cash);
      }
      if (response?.status) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (changedValues: any) => {
    try {
      const weight = form?.getFieldValue("weight") || 0;
      const amount = form?.getFieldValue("amount") || 0;
      const metal = form?.getFieldValue("metal");
      const metalCapital = changeMetalTypeFormat(metal)?.toUpperCase();
      const metalsData = props?.liveRate?.current?.find(
        (item: any) => item?.metal === metalCapital
      );
      const totalLiveAmount = handleLiveRateByType(props?.type, metalsData);

      if (
        isFlipped === false &&
        (changedValues.hasOwnProperty("amount") ||
          changedValues.hasOwnProperty("metal"))
      ) {
        const calculatedWeight =
          amount / (totalLiveAmount * props?.curreny?.exchange_rate);
        form.setFieldsValue({
          weight:
            calculatedWeight < 0 ? 0 : Number(calculatedWeight.toFixed(3)),
        });
      } else if (
        isFlipped &&
        (changedValues.hasOwnProperty("weight") ||
          changedValues.hasOwnProperty("metal"))
      ) {
        const calculatedAmount =
          totalLiveAmount * weight * props?.curreny?.exchange_rate;
        form.setFieldsValue({
          amount:
            calculatedAmount < 0 ? 0 : Number(calculatedAmount.toFixed(2)),
        });
      }
    } catch (err) {
      console.log("err = = = = >", err);
    }
  };

  const handleMetalTypeChange = (metal: string) => {
    form.setFieldsValue({ metal });
    onChange({ metal });
  };
  const getKycDetails = async () => {
    try {
      const response: any = await GET(
        API.GET_KYC_BY_STATUS + props?.User?.id,
        null
      );
      if (response?.status) {
        setKycStatus(response?.data?.isissued);
      }
    } catch (error) {
    } finally {
    }
  };

  const onSubmit = () => {
    if (props?.User?.id === undefined) {
      setUserNotAvailable(true);
    } else if (kycStatus !== "accepted") {
      setKYCModal(true);
    } else {
      setModalInfo(true);
    }
  };

  const dataBody = {
    item: getMetal(form?.getFieldValue("metal")),
    gram: form?.getFieldValue("weight"),
    rate: form?.getFieldValue("amount"),
    type: props?.type,
    walletData: walletData,
  };

  return (
    <Form
      form={form}
      onValuesChange={onChange}
      onFinish={onSubmit}
      initialValues={{ metal: "gold_995" }}
    >
      <div className="homeScreen-formItem1">
        <div
          className={`homeScreen-formButton1 ${
            props?.type === "buy" ? "active" : ""
          }`}
          onClick={() => props?.SetType("buy")}
        >
          <div>{t("buy")} &nbsp;</div>
          <div>
            <BsBagCheck size={20} />
          </div>
        </div>
        <div
          className={`homeScreen-formButton1 ${
            props?.type === "sell" ? "active" : ""
          }`}
          onClick={() => props?.SetType("sell")}
        >
          <div>{t("sell")}</div>
          <MdOutlineSell size={20} />
        </div>
      </div>
      <br />
      <div className="homeScreen-text9">{t("choose_metal_type")}</div>
      <div className="homeScreen-formBox2">
        <Form.Item name="metal" noStyle>
          {["gold_995", "gold_9999", "silver_999"].map((item: any) => {
            return (
              <div
                className="homeScreen-formButton2"
                key={item}
                onClick={() => {
                  handleMetalTypeChange(item);
                  setMetalType(item);
                }}
              >
                <div>{changeMetalTypeFormat(item)}</div>
                <div>
                  {metal_type === item ? (
                    <IoIosRadioButtonOn color="#066949" size={25} />
                  ) : (
                    <IoIosRadioButtonOff color="grey" size={25} />
                  )}
                </div>
              </div>
            );
          })}
        </Form.Item>
      </div>
      <br />
      <Row>
        <Col sm={5} xs={12}>
          <div className="homeScreen-text9">
            {isFlipped
              ? `${t("weight")} ( g )`
              : `${t("amount")} ( ${props?.curreny?.currency_code} )`}
          </div>
          <div className="homeScreen-formInput">
            <Form.Item
              name={isFlipped ? "weight" : "amount"}
              rules={[
                {
                  required: true,
                  message: `${
                    isFlipped ? t("enter_weight_err") : t("enter_amount_err")
                  }!`,
                },
              ]}
            >
              <Input bordered={false} size="large" type="number" />
            </Form.Item>
          </div>
        </Col>
        <Col sm={2} xs={2}>
          <div className="homeScreen-text9" style={{ height: 24 }}></div>
          <div
            className="homeScreen-formInput2"
            onClick={() => setIsFlipped(!isFlipped)}
          >
            <LiaExchangeAltSolid />
          </div>
          <br />
        </Col>
        <Col sm={5} xs={10}>
          <div className="homeScreen-text9">
            {isFlipped
              ? `${t("amount")} ( ${props?.curreny?.currency_code} )`
              : `${t("weight")} ( g )`}
          </div>
          <div className="homeScreen-formInput">
            <Form.Item
              name={isFlipped ? "amount" : "weight"}
              rules={[
                {
                  required: true,
                  message: ` ${
                    isFlipped ? t("enter_amount_err") : t("enter_weight_err")
                  }!`,
                },
              ]}
            >
              <Input bordered={false} size="large" type="number" />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <br />
      <Button
        block
        htmlType={"submit"}
        style={{ height: 50, border: 0, backgroundColor: "#00a56e" }}
      >
        {props?.type === "buy" ? t("buy") : t("sell")} {t("now")}
      </Button>
      <TradeSummary
        data={dataBody}
        User={props?.User}
        walletCash={walletCash}
        isOpen={modalInfo}
        onClose={() => setModalInfo(false)}
        currentInfo={props?.currentInfo}
        changeRefresh={props?.changeRefresh}
      />
      <KycStatusInfo
        data={props?.User?.kyc_status}
        User={props?.User}
        isOpen={kycModal}
        onClose={() => setKYCModal(false)}
        currentInfo={props?.currentInfo}
        changeRefresh={props?.changeRefresh}
      />
      <UserNotAvailable
        isOpen={userNotAvailable}
        onClose={() => setUserNotAvailable(false)}
      />
    </Form>
  );
}
