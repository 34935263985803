import "./styles.scss";
import { Card, Table, Tag, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GET } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  TypeColor,
  changeMetalTypeFormat,
  metalColor,
} from "../../../shared/helpers/functions";
import LiveRateCard from "./components/liveRate";

function Dashboard(props: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enquires, setEnquires] = useState<any>();
  const [enquiresCount, setEnquiresCount] = useState<number>(0);
  const [recentTrade, setRecentTrade] = useState<any>();
  const [tradeCount, setTradeCount] = useState<number>(0);
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const columnsEnquires: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
  ];
  const columnsTrade: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "user",
      key: "name",
      render: (text: any) => <span>{text?.name}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any) => (
        <span>
          <Tag color={TypeColor(text)}>{text}</Tag>
        </span>
      ),
    },
    {
      title: "Metal Type",
      dataIndex: "metal_type",
      key: "metal_type",
      render: (text: any) => (
        <span>
          <Tag color={metalColor(text)}>{changeMetalTypeFormat(text)}</Tag>
        </span>
      ),
    },
    {
      title: "Weight(Gm)",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
  ];
  useEffect(() => {
    getEnquiries();
    getAllTrade();
  }, []);
  const getEnquiries = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.CONTACT_ALL + "?page=1&take=10",
        null
      );
      if (response?.meta?.itemCount) {
        setEnquires(response?.data);
        setEnquiresCount(response?.meta?.itemCount);
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getAllTrade = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.TRADING_LIST + `?order=DESC&page=1&take=10`,
        null
      );
      if (response?.status) {
        setRecentTrade(response.data.data);
        setTradeCount(response?.data?.meta?.itemCount);
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <Row>
          <Col lg={8}>
            <LiveRateCard />
            <br />
            <Row>
              <Col sm={12}>
                <Card
                  title={`Recent Trades (${
                    tradeCount > 11 ? "10+" : tradeCount
                  })`}
                  bodyStyle={{ padding: 0 }}
                  style={{ height: "100%" }}
                  extra={
                    enquiresCount > 10 ? (
                      <span
                        className="Dashboard-enquires-btn"
                        onClick={() => navigate("/admin/trading")}
                      >
                        More
                      </span>
                    ) : null
                  }
                >
                  <Table
                    className="admin-dashboard-table"
                    style={{ paddingLeft: 5, textTransform: "capitalize" }}
                    loading={isLoading}
                    columns={columnsTrade}
                    dataSource={recentTrade}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <Card
              title={`Enquires (${enquiresCount > 11 ? "10+" : enquiresCount})`}
              bodyStyle={{ padding: 0 }}
              className="admin-dashboard-equires-card"
              extra={
                enquiresCount > 10 ? (
                  <span
                    className="Dashboard-enquires-btn"
                    onClick={() => navigate("/admin/enquiries")}
                  >
                    More
                  </span>
                ) : null
              }
            >
              <Table
                className="admin-dashboard-table"
                style={{ paddingLeft: 5 }}
                loading={isLoading}
                columns={columnsEnquires}
                dataSource={enquires}
                pagination={false}
                size="small"
              />
            </Card>
            <br />
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
}

export default Dashboard;
