import { Form, InputNumber, Modal, message, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { metalType } from "../../../../shared/types/metalTypes";
import {
  capitalizeAllWords,
  changeMetalTypeFormat,
} from "../../../../shared/helpers/functions";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { PUT } from "../../../../utlis/apiCalls";
import API from "../../../../config/api";
import TextArea from "antd/es/input/TextArea";
import { statusType } from "../../../../shared/types/kyc";
import "../styles.scss";
import { useSelector } from "react-redux";
export default function UpdateKycModal(props: any) {
  const [isLoading, setIsLoading] = useState<any>(false);
  const [form] = useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const user = useSelector((state: any) => state?.auth?.user);

  const updateKyc = async (val: any) => {
    try {
      setIsLoading(true);
      let obj = {
        isissued: props?.type,
        reasonreject: val?.reason,
      };
      const response: any = await PUT(
        API.UPDATE_KYC_BY_ADMIN + props?.userId + "/" + user?.taxgoToken,
        obj
      );
      if (response?.status) {
        notificationApi.success({
          message: `Successfully ${props?.type} Customer KYC`,
        });
        props?.refreshKyc();
        form.resetFields();
        props?.close();
      } else {
        notificationApi.error({ message: response?.message });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <Modal
        open={props?.isOpen}
        onOk={form.submit}
        okButtonProps={{ loading: isLoading }}
        okText="Submit"
        onCancel={props?.close}
        // closeIcon={ />}
        closable={false}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="admin-CustomerScreen-txt2"
        >
          {capitalizeAllWords(props?.type)} KYC{" "}
          <CloseOutlined
            color="#000"
            onClick={props?.close}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Form onFinish={updateKyc} form={form}>
          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: props?.type === statusType.reject ? true : false,
                message: "Please input your Reason!",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
