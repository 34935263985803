import { Modal, Radio, Space, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsBank, BsCashStack } from "react-icons/bs";
import { FaShop } from "react-icons/fa6";
import { IoWalletOutline } from "react-icons/io5";

export default function WithDrawTypeModal(props: any) {
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [paymentType, setPaymentType] = useState("wallet");

  const handleSubmit = async () => {
    if (paymentType !== "bank") {
      props?.form?.setFieldValue("payment_type", paymentType);
      props?.onFinish();
      props?.onCancel();
    } else {
      notificationApi.info({
        message: t("sorry_payment_option_unavailable"),
      });
    }
  };
  return (
    <>
      {contextHolder}
      <Modal
        open={props?.open}
        okText={t("submit")}
        cancelText={t("cancel")}
        onOk={handleSubmit}
        onCancel={props?.onCancel}
      >
        <div className="app-HomeScreen-txt5">
          {t("select_withdrawal_method")}
        </div>
        <br />
        <Radio.Group
          onChange={(e: any) => {
            setPaymentType(e?.target?.value);
          }}
          defaultValue={paymentType}
          value={paymentType}
        >
          <Space direction="vertical" style={{ paddingLeft: 20 }}>
            <Radio value={"wallet"} style={{ paddingBottom: 10 }}>
              <div>
                {" "}
                <span
                  className="app-HomeScreen-txt10"
                  style={{ color: props.color }}
                >
                  {" "}
                  <IoWalletOutline className="app-HomeScreen-icon" />
                  {t("withdraw_via_wallet")}
                </span>
              </div>
            </Radio>
            <Radio value={"bank"} style={{ paddingBottom: 10 }}>
              <span
                className="app-HomeScreen-txt10"
                style={{ color: props.color }}
              >
                <BsBank className="app-HomeScreen-icon" />
                {t("withdraw_via_bank")}
              </span>
            </Radio>
            <Radio value={"payment_at_shop"} style={{ paddingBottom: 10 }}>
              <span
                className="app-HomeScreen-txt10"
                style={{ color: props.color }}
              >
                <FaShop className="app-HomeScreen-icon" />
                {t("withdraw_via_shop")}
              </span>
            </Radio>
            <Radio value={"cash_delivery"} style={{ paddingBottom: 10 }}>
              <span
                className="app-HomeScreen-txt10"
                style={{ color: props.color }}
              >
                {" "}
                <BsCashStack className="app-HomeScreen-icon" />
                {t("cash_delivery")}
              </span>
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
}
