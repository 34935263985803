import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";

import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  changeMetalTypeFormat,
  fetchUserCountry,
} from "../../../shared/helpers/functions";
import { metalType } from "../../../shared/types/metalTypes";

import AppPageHeader from "../../routes/appPageHeader";
import AutoCompleteLocation from "./components/autoCompleteLocation";

import API from "../../../config/api";
import { GET, POST } from "../../../utlis/apiCalls";
import ConfirmModal from "./components/confirmModal";
import { statusType } from "../../../shared/types/kyc";
import KycStatusInfo from "../../../components/kycInformation";

export default function RedeemScreen() {
  const [form] = useForm();
  const { t } = useTranslation();
  const CurrencyInfo = useSelector((state: any) => state?.currencyInfo);
  const User = useSelector((state: any) => state?.auth?.user);

  const currencyInfo = useSelector(
    (state: any) => state.currencyInfo.currencyInfo
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  const [location, setLocation] = useState<any>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [kycModal, setKYCModal] = useState<boolean>(false);
  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  const [kycStatus, setKycStatus] = useState();

  useEffect(() => {
    fetchUserCountry(currencyInfo)
      .then((res) => {
        setDefaultCountryCode(res);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
    getKycDetails();
  }, []);

  useEffect(() => {
    if (defaultCountryCode) {
      form.setFieldsValue({ code: defaultCountryCode });
    }
  }, [defaultCountryCode, form]);

  const getKycDetails = async () => {
    try {
      const response: any = await GET(API.GET_KYC_BY_STATUS + User?.id, null);
      if (response?.status) {
        setKycStatus(response?.data?.isissued);
      }
    } catch (error) {
    } finally {
    }
  };

  const onFinish = () => {
    setModalOpen(true);
  };
  const kycStatusType = (text: any) => {
    if (text === statusType.not_submitted) {
      return "Not submitted";
    }
    if (text === statusType.pending) {
      return "Pending";
    }
    if (text === statusType.reject) {
      return "Reject";
    }
  };

  async function handleRedeem() {
    try {
      setIsLoading(true);
      const values = form.getFieldsValue();
      let obj = {
        user_id: User?.id,
        metals_amount: {
          gold_995: values?.gold_995,
          gold_9999: values?.gold_9999,
          silver_999: values?.silver_999,
        },
        phone_number: `+${values?.code} ${values?.phone_number}`,
        address: values?.address,
        lat: location?.lat,
        long: location?.long,
        location: location?.address,
        type: values?.type,
      };

      const response: any = await POST(API.REDEEM_CREATE, obj);
      if (response?.status) {
        notificationApi.success({
          message: t("redeem_request_created_successfully"),
        });
        form.resetFields();
      } else {
        notificationApi.error({
          message: response?.message,
        });
      }
    } catch (error) {
    } finally {
      setModalOpen(false);
      setIsLoading(false);
    }
  }
  const locationPick = async (loca: any) => {
    setLocation(loca);
    form.setFieldsValue({
      address: loca.address ?? loca.premise,
      location: loca.address ?? loca.premise,
    });
  };
  const handleRedeemSubmit = () => {
    if (kycStatus !== "accepted") {
      setKYCModal(true);
    } else {
      form.submit();
    }
  };

  const selectBefore = (
    <Form.Item
      name="code"
      rules={[{ required: true, message: t("required") }]}
      style={{ margin: 0, height: "5vh" }}
      className="app-RedeemScreen-items-selectBefore"
    >
      <Select
        style={{ width: "100px", color: "white" }}
        options={CurrencyInfo?.currencyInfo.map((item: any) => ({
          value: item.dail_code,
          label: (
            <>
              <img
                alt={item?.country_code}
                src={`https://flagsapi.com/${item?.country_code}/flat/24.png`}
              />
              &nbsp;
              {"+"}
              {item.dail_code}
            </>
          ),
        }))}
      />
    </Form.Item>
  );

  return (
    <div>
      <AppPageHeader title="Redeem" />
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row>
          <Col md={2} xs={12}></Col>
          <Col md={7} xs={12} style={{ padding: "20px" }}>
            <Row className="app-RedeemScreen">
              {User?.kyc_status != "accepted" ? (
                <>
                  <Alert
                    message={
                      User?.kyc_status != statusType.accept
                        ? `KYC is ${kycStatusType(User?.kyc_status)}`
                        : `KYC is ${User?.kyc_status}`
                    }
                    description={
                      "You can access this feature after Kyc approval"
                    }
                    type={"error"}
                    showIcon
                    style={{ marginBottom: 20, fontSize: "18px" }}
                  />{" "}
                </>
              ) : null}
              {Object.values(metalType).map((item: any, index: any) =>
                item === "cash" ? null : (
                  <Col md={6}>
                    <Form.Item
                      label={
                        <div className="app-RedeemScreen-txt2">
                          {changeMetalTypeFormat(item)}
                        </div>
                      }
                      name={item}
                      initialValue={0}
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          message: t("weight_gt_0_err"),
                        },
                      ]}
                      className="app-RedeemScreen-items"
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                          color: "#ffffffee",
                        }}
                        size="large"
                        controls={false}
                        addonAfter={
                          <div style={{ color: "#ffffffee" }}>{t("gram")}</div>
                        }
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                )
              )}
              <Col md={6}>
                <Form.Item
                  name={"type"}
                  label={
                    <div className="app-RedeemScreen-txt2">
                      {t("payment_type")}
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("select_option"),
                    },
                  ]}
                >
                  <Radio.Group
                    name="radiogroup"
                    className="d-flex justify-content-start"
                  >
                    <Radio value={"amount"} className="app-RedeemScreen-txt3">
                      {t("in_amount")}
                    </Radio>
                    <Radio value={"weight"} className="app-RedeemScreen-txt3">
                      {t("in_weight")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  className="app-RedeemScreen-items"
                  name="phone_number"
                  rules={[
                    { required: true, message: t("required") },
                    {
                      pattern: /^[0-9]*$/,
                      message: t("please_enter_valid_phone_number"),
                    },
                  ]}
                  label={
                    <div className="app-RedeemScreen-txt2">
                      {t("phone_number")}
                    </div>
                  }
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                    size="large"
                    addonBefore={selectBefore}
                    placeholder={t("enter_phone_number")}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  className="app-RedeemScreen-items"
                  name="location"
                  label={
                    <div className="app-RedeemScreen-txt2">
                      {t("search_location")}
                    </div>
                  }
                >
                  <AutoCompleteLocation
                    style={{
                      background: "yellow",
                    }}
                    label={false}
                    size="large"
                    value={location?.location}
                    setCurrentLocation={(value: any) => locationPick(value)}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  name="address"
                  rules={[{ required: true, message: t("required") }]}
                  label={
                    <div className="app-RedeemScreen-txt2">{t("address")}</div>
                  }
                  className="app-RedeemScreen-items"
                >
                  <TextArea rows={5} placeholder={t("enter_address")} />
                </Form.Item>
              </Col>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  loading={isLoading}
                  onClick={handleRedeemSubmit}
                  className="app-RedeemScreen-Buttons"
                  style={{ width: "150px", height: "40px" }}
                >
                  {t("submit")}
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        modalOpen={modalOpen}
        handleRedeem={handleRedeem}
        close={() => setModalOpen(false)}
        isLoading={isLoading}
        form={form}
      />
      <KycStatusInfo
        data={kycStatus}
        User={User}
        isOpen={kycModal}
        onClose={() => setKYCModal(false)}
      />
    </div>
  );
}
