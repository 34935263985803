import { Card, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  StatusColor,
  TypeColor,
  changeMetalTypeFormat,
  metalColor,
} from "../../../../shared/helpers/functions";

function DataTable(props: any) {
  const navigate = useNavigate();
  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "user",
      key: "name",
      render: (text: any) => <span>{text?.name}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any) => (
        <span>
          <Tag color={TypeColor(text)}>{text}</Tag>
        </span>
      ),
    },
    {
      title: "Metal Type",
      dataIndex: "metal_type",
      key: "metal_type",
      render: (text: any) => (
        <span>
          <Tag color={metalColor(text)}>{changeMetalTypeFormat(text)}</Tag>
        </span>
      ),
    },
    {
      title: "Amount (AED)",
      dataIndex: "purchased_amount",
      key: "purchased_amount",
      render: (text: any) => <span>{text?.toFixed(2)}</span>,
    },
    {
      title: "Weight (gm)",
      dataIndex: "weight",
      key: "weight",
      render: (text: any) => <span>{text?.toFixed(2)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => (
        <span style={{ textTransform: "capitalize" }}>
          <Tag color={StatusColor(text)}>
            {text}
          </Tag>
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "view",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <MdOutlineRemoveRedEye
          size={25}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/admin/sipDetails/${record.id}`)}
        />
      ),
    },
  ];

  return (
    <>
      <Card className="commen-table-Card">
        <Table
          loading={props?.isLoading}
          columns={columns}
          dataSource={props?.allTrade}
          pagination={false}
          className="commen-table-custom-scrollbar"
          size="small"
        />
      </Card>
    </>
  );
}

export default DataTable;
