import { Line } from "@ant-design/charts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Charts = (props: any) => {
  const { t } = useTranslation();
  const totalData = [
    { year: "1991", value: 1 },
    { year: "1992", value: 2 },
    { year: "1993", value: 3 },
    { year: "1994", value: 3 },
    { year: "1995", value: 4 },
    { year: "1996", value: 5 },
    { year: "1997", value: 6 },
  ];
  const [data, setData] = useState(totalData);

  const config = {
    data: data,
    xField: "year",
    yField: "value",
    point: {
      size: 5,
      style: {
        fill: "#00a56e",
        stroke: "#00a56e",
        lineWidth: 3,
      },
    },
    xAxis: {
      label: {
        style: {
          fill: "#FFFFFF", // Set x-axis label font color to white
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fill: "#FFFFFF", // Set y-axis label font color to white
        },
      },
    },
    tooltip: false,
    line: {
      style: {
        stroke: "#00a56e",
      },
    },
  };

  const handleButtonClick = (years: any) => {
    let newData;
    switch (years) {
      case 1:
        newData = [
          { year: "1991", value: 1 },
          { year: "1992", value: 1.5 },
          { year: "1993", value: 1.5 },
          { year: "1994", value: 2 },
          { year: "1995", value: 2 },
          { year: "1996", value: 3 },
        ];
        break;
      case 2:
        newData = [
          { year: "1991", value: 1 },
          { year: "1992", value: 3 },
          { year: "1993", value: 3 },
          { year: "1994", value: 3.5 },
          { year: "1995", value: 4 },
          { year: "1996", value: 5 },
        ];
        break;
      case 5:
        newData = totalData;
        break;
      default:
        newData = totalData;
    }
    setData(newData);
  };
  return (
    <div className="homeScreen-box7" style={{ height: "100%" }}>
      <div style={{ flex: 1 }}>
        <div style={{ padding: 10 }}>
          <div className="homeScreen-text11">{t("Show_growth_for")}</div>
          <div style={{ margin: 10 }} />
          <div className="homeScreen-rowBox">
            <div
              className="homeScreen-button1"
              onClick={() => handleButtonClick(1)}
            >
              {t("1_Year")}
            </div>
            <div
              className="homeScreen-button1"
              onClick={() => handleButtonClick(2)}
            >
              {t("2_Year")}
            </div>
            <div
              className="homeScreen-button1"
              onClick={() => handleButtonClick(5)}
            >
              {t("5_Year")}
            </div>
          </div>
        </div>
        <br />
        <Line
          containerStyle={{
            height: 220,
            borderRadius: 10,
          }}
          {...config}
        />
      </div>
    </div>
  );
};

export default Charts;
