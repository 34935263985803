import {
  Alert,
  Card,
  Form,
  InputNumber,
  Modal,
  Select,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../../../config/api";
import {
  changeMetalTypeFormat,
  handleLiveRateByType,
} from "../../../../shared/helpers/functions";
import { metalType } from "../../../../shared/types/metalTypes";
import { GET, PUT } from "../../../../utlis/apiCalls";
import "../styles.scss";
export default function AddAmountModal(props: any) {
  const liveRate = useSelector((state: any) => state.liveRate);
  const [notificationApi, contextHolder] = notification.useNotification();
  const currentInfo = useSelector((state: any) => state?.currencyInfo?.current);
  const User = useSelector((state: any) => state.auth.user);
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [walletCash, setWalletCash] = useState<any>();
  const { t } = useTranslation();
  const navigation = useNavigate();

  useEffect(() => {
    LoadWallet();
  }, []);

  const LoadWallet = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.GET_WALLET + User?.id, null);
      if (response?.status) {
        const wallet = response?.data;
        const cash = wallet?.find(
          (item: any) => metalType.cash === item?.metal_type
        );
        setWalletCash(cash);
      } else {
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  async function handleSip(values: any): Promise<void> {
    setIsLoading(true);
    try {
      let paymentType = values?.payment_type;
      let obj = {
        amount: Number(values?.amount / currentInfo?.exchange_rate),
        weight: values?.weight,
        payment_type: values?.payment_type,
      };
      const response: any = await PUT(API.SIP_UPDATE + props?.sip?.id, obj);
      console.log("responseessssss", response);
      if (paymentType === "card") {
        navigation("/auth/checkoutScreen", {
          state: {
            paid_module: "sip_add",
            module_id: response.data.sipHistoryId,
          },
        });
      } else {
        if (response?.status) {
          props?.setRefresh();
          notificationApi.success({
            message: t("successful_deposited_weight"),
          });
          form?.resetFields();
          props?.onClose();
        } else {
          notificationApi.error({ message: response?.message });
        }
      }
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
  }
  const weightToAmount = () => {
    const weight = form?.getFieldValue("weight");
    const metalCapital = changeMetalTypeFormat(
      props?.sip?.metal_type
    )?.toUpperCase();
    const metalsData = liveRate?.current?.find(
      (item: any) => item?.metal === metalCapital
    );
    const totalLiveAmount = handleLiveRateByType("buy", metalsData);
    const amount = totalLiveAmount * weight * currentInfo?.exchange_rate;
    form.setFieldValue("amount", Number(amount?.toFixed(2)));
  };

  const amountToWeight = () => {
    const amount = form?.getFieldValue("amount");
    const metalCapital = changeMetalTypeFormat(
      props?.sip?.metal_type
    )?.toUpperCase();
    const metalsData = liveRate?.current?.find(
      (item: any) => item?.metal === metalCapital
    );
    const totalLiveAmount = handleLiveRateByType("buy", metalsData);
    const weight = amount / (totalLiveAmount * currentInfo?.exchange_rate);
    form.setFieldValue("weight", Number(weight?.toFixed(3)));
  };
  const balance = () => {
    return (
      walletCash?.balance * currentInfo?.exchange_rate -
      form?.getFieldValue("amount")
    );
  };
  const findWeightinInterval = () => {
    let intervalTime: any;
    //conditon to check if interval is daily
    if (props?.sip?.interval === "daily") {
      intervalTime = props?.sip?.tenure * 365;
    }
    //conditon to check if interval is weekly
    if (props?.sip?.interval === "weekly") {
      intervalTime = props?.sip?.tenure * 52.143;
    }
    //conditon to check if interval is monthly
    if (props?.sip?.interval === "monthly") {
      intervalTime = props?.sip?.tenure * 12;
    }
    //conditon to check if interval is yearly
    if (props?.sip?.interval === "yearly") {
      intervalTime = props?.sip?.tenure;
    }
    return props?.sip?.target_weight / intervalTime;
  };
  return (
    <>
      {contextHolder}
      <Modal
        open={props?.open}
        onCancel={props?.onClose}
        onOk={form.submit}
        okText={t("submit")}
        cancelText={t("cancel")}
        cancelButtonProps={{ loading: isLoading, className: "customBtn" }}
        okButtonProps={{ loading: isLoading, className: "customBtn" }}
      >
        <div className="app-SipScreen-txt7">{t("payment")}</div>
        <Alert
          message={
            <div>
              <div>
                <span>{t("target_weight")} : </span>
                <span>
                  {props?.sip?.target_weight} {t("gram")}
                </span>
              </div>
              <div>
                <span>{t("deposit_weight_in_each_interval")} : </span>
                <span>
                  {findWeightinInterval()?.toFixed(2)} {t("gram")}
                </span>
              </div>
            </div>
          }
          type="info"
          style={{ marginBottom: 20 }}
        />
        <Form
          onFinish={handleSip}
          form={form}
          layout="vertical"
          //labelCol={{ span: 8 }}
          //wrapperCol={{ span: 16 }}
        >
          <div>
            <Form.Item
              label={<div className="app-Sipscreen-txt10 ">{t("amount")}</div>}
              name={"amount"}
              style={{ marginBottom: 10 }}
              rules={[
                {
                  type: "number",
                  min: 0,
                  // Update this line to allow 0 as a valid input
                  message: t("amount_gt_0_err"),
                },
                {
                  required: true,
                  message: t("enter_amount_err"),
                },
              ]}
            >
              <InputNumber
                className="app-Sipscreen-modal-Input"
                placeholder={t("amount")}
                style={{ marginLeft: 10 }}
                disabled={isLoading}
                onChange={amountToWeight}
                type="number"
                step="0.01"
                addonAfter={currentInfo?.currency_code}
              />
            </Form.Item>
            <div
              className="app-Sipscreen-txt12"
              style={{ marginLeft: 30, marginBottom: 10 }}
            >
              {t("or")}
            </div>
            <Form.Item
              label={<div className="app-Sipscreen-txt10 ">{t("weight")}</div>}
              name={"weight"}
              rules={[
                {
                  type: "number",
                  min: 0,
                  // Update this line to allow 0 as a valid input
                  message: t("weight_gt_0_err"),
                },
                {
                  required: true,
                  message: t("enter_weight_err"),
                },
              ]}
            >
              <InputNumber
                className="app-Sipscreen-modal-Input"
                placeholder={t("weight")}
                style={{ marginLeft: 10 }}
                disabled={isLoading}
                onChange={weightToAmount}
                type="number"
                step="0.01"
                addonAfter={t("gram")}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="app-Sipscreen-txt10">
                  {t("choose_payment_type")}
                </span>
              }
              // initialValue={"wallet"}
              name={"payment_type"}
              rules={[
                {
                  required: true,
                  message: "Please select a payment type.",
                },
              ]}
            >
              <Select
                placeholder="Select a payment type"
                className="app-Sipscreen-modal-Input"
              >
                <Select.Option value="bank">{t("bank")}</Select.Option>
                <Select.Option value="card">{t("card")} </Select.Option>
                <Select.Option value="wallet">{t("wallet")}</Select.Option>
                <Select.Option value="cash">{t("cash")}</Select.Option>
                <Select.Option value="cash_pickup">
                  {t("cash_pickup")}
                </Select.Option>
                <Select.Option value="payment_at_shop">
                  {t("payment_at_shop")}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
        {form.getFieldValue("payment_type") === "wallet" ? (
          <div className="app-SipScreen-Modal-total-box">
            {" "}
            <Card
              style={{ width: 320 }}
              className={
                balance() < 0
                  ? "app-SipScreen-red-card dark:app-SipScreen-red-card-dark"
                  : "app-SipScreen-green-card dark:app-SipScreen-green-card-dark"
              }
            >
              <Row>
                <Col>{t("cash_in_wallet")} :</Col>
                <Col>
                  {(walletCash?.balance * currentInfo?.exchange_rate)?.toFixed(
                    2
                  )}{" "}
                  {currentInfo?.currency_code}
                </Col>
              </Row>
              <Row>
                <Col>{t("cost")} :</Col>
                <Col>
                  {form?.getFieldValue("amount")?.toFixed(2)}{" "}
                  {currentInfo?.currency_code}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>{t("balance_in_wallet")} :</Col>
                <Col>
                  {(
                    walletCash?.balance * currentInfo?.exchange_rate -
                    form?.getFieldValue("amount")
                  )?.toFixed(2)}{" "}
                  {currentInfo?.currency_code}
                </Col>
              </Row>
            </Card>
            {balance() < 0 ? (
              <div style={{ width: 300, color: "red" }}>
                * {t("insufficient_balance_in_wallet")}
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal>
    </>
  );
}
