import "./styles.scss";
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Tag,
  notification,
} from "antd";
import { Container, Table } from "react-bootstrap";
import PageHeader from "../../routes/pageHeader";
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import API from "../../../config/api";
import { GET, PUT } from "../../../utlis/apiCalls";
import { useCallback, useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { flex } from "ui-box";
function Script(props: any) {
  const [form] = useForm();
  const liveRate: any = useSelector((state: any) => state.liveRate);
  const [scripts, setScripts] = useState<any>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [variant, setVariant] = useState<any>(0);
  const [loadingStates, setLoadingStates] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getScriptAll(false);
  }, []);

  const getScriptAll = async (refreshall: any) => {
    try {
      if (refreshall) {
        setIsLoading(true);
      }
      const response: any = await GET(API.SCRIPT_ALL, null);
      if (response?.status) {
        setScripts(response?.data);
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  function debounce(func: any) {
    let timeoutId: any;

    return function (...args: any) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func(...args);
      }, 500);
    };
  }

  // const handleScriptChange = async (item: any, index: any) => {
  //   try {
  //     setLoadingStates((prevState: any) => ({
  //       ...prevState,
  //       [index]: true,
  //     }));

  //     let obj = {
  //       buy_variant: item?.buy_variant,
  //       sell_variant: item?.sell_variant,
  //       discount: item?.discount,
  //       premium: item?.premium,
  //     };
  //     const response: any = await PUT(API.SCRIPT_UPDATE + item?.id, obj);
  //     if (response?.status) {
  //       setVariant(item);
  //     } else {
  //       notificationApi.error(response);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoadingStates((prevState: any) => ({
  //       ...prevState,
  //       [index]: false,
  //     }));
  //   }
  // };

  // const optimizedFn = useCallback(debounce(handleScriptChange), []);

  const onInputChange = (val: any, Itemname: any) => {
    if (val === null || val === "") {
      form.setFieldValue(Itemname, 0);
      if (Itemname.startsWith("1")) {
        notificationApi.error({
          message: "buy variant must not be less than 0",
        });
      } else if (Itemname.startsWith("2")) {
        notificationApi.error({
          message: "sell variant must not be less than 0",
        });
      } else if (Itemname.startsWith("3")) {
        notificationApi.error({
          message: "discount must not be less than 0",
        });
      } else if (Itemname.startsWith("4")) {
        notificationApi.error({
          message: "premium must not be less than 0",
        });
      }
    }
  };

  const handleScriptSave = async (item: any, mode: any, index: any) => {
    try {
      setLoadingStates((prevState: any) => ({
        ...prevState,
        [index]: true,
      }));

      let Url = API.SCRIPT_UPDATE + item;

      let obj1 = {
        buy_variant: form.getFieldValue("10"),
        sell_variant: form.getFieldValue("20"),
        discount: form.getFieldValue("30"),
        premium: form.getFieldValue("40"),
      };
      let obj2 = {
        buy_variant: form.getFieldValue("11"),
        sell_variant: form.getFieldValue("21"),
        discount: form.getFieldValue("31"),
        premium: form.getFieldValue("41"),
      };
      let obj3 = {
        buy_variant: form.getFieldValue("12"),
        sell_variant: form.getFieldValue("22"),
        discount: form.getFieldValue("32"),
        premium: form.getFieldValue("42"),
      };
      let other = {
        buy_variant: 0,
        sell_variant: 0,
        discount: 0,
        premium: 0,
      };

      let body =
        item === "1" && mode === "save"
          ? obj1
          : item === "2" && mode === "save"
          ? obj2
          : item === "3" && mode === "save"
          ? obj3
          : other;

      const response: any = await PUT(Url, body);
      if (response?.status) {
        await getScriptAll(false);
        if (mode === "reset") {
          const fieldNames = form.getFieldsValue();
          const resetFields = Object.keys(fieldNames).reduce(
            (acc: any, key) => {
              if (key.endsWith(String(item - 1))) {
                acc[key] = 0;
              }
              return acc;
            },
            {}
          );
          form.setFieldsValue(resetFields);
        }
      } else {
        notificationApi.error(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingStates((prevState: any) => ({
        ...prevState,
        [index]: false,
      }));
    }
  };
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Script"}>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button
              type="primary"
              loading={isLoading}
              onClick={() => {
                getScriptAll(true);
              }}
              style={{ width: "100px" }}
            >
              Refresh
            </Button>
          </div>
        </PageHeader>
        <Form form={form}>
          <Card className="admin-ScriptScreen-table-card">
            <Table bordered hover>
              <thead>
                <tr>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Positon
                  </th>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Metal
                  </th>
                  <th colSpan={3} className="admin-script-center-cell">
                    Buy
                  </th>
                  <th colSpan={3} className="admin-script-center-cell">
                    Sell
                  </th>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Discount
                  </th>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Premium
                  </th>
                  <th rowSpan={2} className="admin-script-center-cell"></th>
                </tr>
                <tr>
                  <th className="admin-script-center-cell">Live Rate</th>
                  <th className="admin-script-center-cell">Variant</th>
                  <th className="admin-script-center-cell">Total Live Rate</th>
                  <th className="admin-script-center-cell">Live Rate</th>
                  <th className="admin-script-center-cell">Variant</th>
                  <th className="admin-script-center-cell">Total Live Rate</th>
                </tr>
              </thead>
              <tbody>
                {scripts?.map((item: any, index: any) => (
                  <tr>
                    <td className="admin-script-center-cell">
                      {item.position}
                    </td>
                    <td className="admin-script-center-cell">
                      <Tag>{item.script_name}</Tag>
                    </td>
                    <td className="admin-script-center-cell">
                      {liveRate?.current
                        ?.filter((i: any) => i?.metal === item?.script_name)[0]
                        ?.liveBuyRate?.toFixed(2)}
                    </td>
                    <td className="admin-script-center-cell">
                      {" "}
                      <Form.Item
                        name={`1${index}`}
                        initialValue={item?.buy_variant || 0}
                      >
                        <InputNumber
                          className="color-dark"
                          style={{ width: "200px" }}
                          min={0}
                          size="middle"
                          controls
                          value={item?.buy_variant}
                          onChange={(val) => onInputChange(val, `1${index}`)}
                        />
                      </Form.Item>
                    </td>
                    <td className="admin-script-center-cell">
                      {Number(form.getFieldValue(`1${index}`)) +
                        Number(
                          liveRate?.current
                            ?.filter(
                              (i: any) => i?.metal === item?.script_name
                            )[0]
                            ?.liveBuyRate?.toFixed(2)
                        )}
                    </td>
                    <td className="admin-script-center-cell">
                      {liveRate?.current
                        ?.filter((i: any) => i?.metal === item?.script_name)[0]
                        ?.liveSellRate?.toFixed(2)}
                    </td>
                    <td className="admin-script-center-cell">
                      {" "}
                      <Form.Item
                        name={`2${index}`}
                        initialValue={item?.sell_variant || 0}
                      >
                        <InputNumber
                          className="color-dark"
                          style={{ width: "200px" }}
                          min={0}
                          controls
                          value={item?.sell_variant}
                          size="middle"
                          onChange={(val) => onInputChange(val, `2${index}`)}
                        />
                      </Form.Item>
                    </td>
                    <td className="admin-script-center-cell">
                      {Number(
                        liveRate?.current
                          ?.filter(
                            (i: any) => i?.metal === item?.script_name
                          )[0]
                          ?.totalSellAmount?.toFixed(2)
                      ) + Number(form.getFieldValue(`2${index}`))}
                    </td>
                    <td className="admin-script-center-cell">
                      <Form.Item
                        name={`3${index}`}
                        initialValue={item?.discount || 0}
                      >
                        <InputNumber
                          className="color-dark"
                          min={0}
                          size="middle"
                          value={item?.discount}
                          onChange={(val) => onInputChange(val, `3${index}`)}
                        />
                      </Form.Item>
                    </td>
                    <td className="admin-script-center-cell">
                      <Form.Item
                        name={`4${index}`}
                        initialValue={item?.premium || 0}
                      >
                        <InputNumber
                          className="color-dark"
                          min={0}
                          size="middle"
                          value={item?.premium}
                          onChange={(val) => onInputChange(val, `4${index}`)}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Button
                        loading={loadingStates[`1${index}`]}
                        onClick={() =>
                          handleScriptSave(item?.id, "save", `1${index}`)
                        }
                        style={{ width: "100px" }}
                      >
                        Save
                      </Button>
                    </td>
                    <td>
                      <Popconfirm
                        title="Reset All"
                        description="Are you sure to reset all values?"
                        onConfirm={() =>
                          handleScriptSave(item.id, "reset", `2${index}`)
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button style={{ width: "100px" }}>Reset</Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Form>
        {/* <Card className="admin-ScriptScreen-table-card">
          <Table bordered hover>
            <thead>
              <tr>
                <th rowSpan={2} className="admin-script-center-cell">
                  Positon
                </th>
                <th rowSpan={2} className="admin-script-center-cell">
                  Metal
                </th>
                <th colSpan={3} className="admin-script-center-cell">
                  Buy
                </th>
                <th colSpan={3} className="admin-script-center-cell">
                  Sell
                </th>
                <th rowSpan={2} className="admin-script-center-cell">
                  Discount
                </th>
                <th rowSpan={2} className="admin-script-center-cell">
                  Premium
                </th>
                <th rowSpan={2} className="admin-script-center-cell"></th>
              </tr>
              <tr>
                <th className="admin-script-center-cell">Live Rate</th>
                <th className="admin-script-center-cell">Variant</th>
                <th className="admin-script-center-cell">Total Live Rate</th>
                <th className="admin-script-center-cell">Live Rate</th>
                <th className="admin-script-center-cell">Variant</th>
                <th className="admin-script-center-cell">Total Live Rate</th>
              </tr>
            </thead>
            <tbody>
              {scripts?.map((item: any, index: any) => (
                <tr>
                  <td className="admin-script-center-cell">{item.position}</td>
                  <td className="admin-script-center-cell">
                    <Tag>{item.script_name}</Tag>
                  </td>
                  <td className="admin-script-center-cell">
                    {liveRate?.current
                      ?.filter((i: any) => i?.metal === item?.script_name)[0]
                      ?.liveBuyRate?.toFixed(2)}
                  </td>
                  <td className="admin-script-center-cell">
                    {" "}
                    <div>
                      <InputNumber
                        className="color-dark"
                        addonBefore={
                          loadingStates[`1${index}`] ? (
                            <AiOutlineLoading className="rotating" />
                          ) : (
                            <IoArrowUp
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleScriptChange(
                                  {
                                    ...item,
                                    buy_variant: item?.buy_variant + 1,
                                  },
                                  `1${index}`
                                )
                              }
                            />
                          )
                        }
                        addonAfter={
                          loadingStates[`2${index}`] ? (
                            <AiOutlineLoading className="rotating" />
                          ) : (
                            <IoArrowDown
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleScriptChange(
                                  {
                                    ...item,
                                    buy_variant: item?.buy_variant - 1,
                                  },
                                  `2${index}`
                                )
                              }
                            />
                          )
                        }
                        style={{ width: "200px" }}
                        min={0}
                        controls={false}
                        value={item?.buy_variant}
                        onChange={(val) =>
                          optimizedFn({
                            ...item,
                            buy_variant: val,
                          })
                        }
                      />
                    </div>
                  </td>
                  <td className="admin-script-center-cell">
                    {liveRate?.current
                      ?.filter((i: any) => i?.metal === item?.script_name)[0]
                      ?.totalBuyAmount?.toFixed(2)}
                  </td>
                  <td className="admin-script-center-cell">
                    {liveRate?.current
                      ?.filter((i: any) => i?.metal === item?.script_name)[0]
                      ?.liveSellRate?.toFixed(2)}
                  </td>
                  <td className="admin-script-center-cell">
                    {" "}
                    <div>
                      <InputNumber
                        className="color-dark"
                        addonBefore={
                          loadingStates[`3${index}`] ? (
                            <AiOutlineLoading className="rotating" />
                          ) : (
                            <IoArrowUp
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleScriptChange(
                                  {
                                    ...item,
                                    sell_variant: item?.sell_variant + 1,
                                  },
                                  `3${index}`
                                )
                              }
                            />
                          )
                        }
                        addonAfter={
                          loadingStates[`4${index}`] ? (
                            <AiOutlineLoading className="rotating" />
                          ) : (
                            <IoArrowDown
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleScriptChange(
                                  {
                                    ...item,
                                    sell_variant: item?.sell_variant - 1,
                                  },
                                  `4${index}`
                                )
                              }
                            />
                          )
                        }
                        style={{ width: "200px" }}
                        min={0}
                        controls={false}
                        value={item?.sell_variant}
                        onChange={(val) =>
                          optimizedFn({
                            ...item,
                            sell_variant: val,
                          })
                        }
                      />
                    </div>
                  </td>
                  <td className="admin-script-center-cell">
                    {liveRate?.current
                      ?.filter((i: any) => i?.metal === item?.script_name)[0]
                      ?.totalSellAmount?.toFixed(2)}
                  </td>
                  <td className="admin-script-center-cell">
                    <InputNumber
                      className="color-dark"
                      value={item?.discount}
                      onChange={(val) =>
                        optimizedFn({
                          ...item,
                          discount: val,
                        })
                      }
                    />
                  </td>
                  <td className="admin-script-center-cell">
                    <InputNumber
                      className="color-dark"
                      value={item?.premium}
                      onChange={(val) =>
                        optimizedFn({
                          ...item,
                          premium: val,
                        })
                      }
                    />
                  </td>
                  <td style={{ width: 80 }}>
                    <Button
                      onClick={() =>
                        handleScriptChange(
                          {
                            id: item?.id,
                            buy_variant: 0,
                            sell_variant: 0,
                            discount: 0,
                            premium: 0,
                          },
                          `5${index}`
                        )
                      }
                    >
                      {" "}
                      {loadingStates[`5${index}`] ? (
                        <AiOutlineLoading className="rotating" />
                      ) : (
                        "Reset"
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card> */}
      </Container>
    </div>
  );
}

export default Script;
