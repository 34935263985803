const ThemeObj = {
  token: {
    colorPrimary: "#00a56e",
    fontFamily: "DMSans-Regular",
    borderRadius: 4,
  },
  components: {},
};

export default ThemeObj;
