import React, { useEffect, useState } from "react";
import "./styles.scss";

import { Col, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
} from "antd";
import { useForm } from "antd/es/form/Form";

import {
  changeMetalTypeFormat,
  fetchUserCountry,
} from "../../../shared/helpers/functions";
import { metalType } from "../../../shared/types/metalTypes";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AppPageHeader from "../../routes/appPageHeader";
import { GET, PUT } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import { statusType } from "../../../shared/types/kyc";
import KycStatusInfo from "../../../components/kycInformation";

function SendGift() {
  const [form] = useForm();
  const { t } = useTranslation();

  const User = useSelector((state: any) => state?.auth?.user);
  const CurrencyInfo = useSelector((state: any) => state?.currencyInfo);
  const currencyInfo = useSelector(
    (state: any) => state.currencyInfo.currencyInfo
  );
  const [isLoading, setIsLoading] = useState<any>(false);
  const [kycModal, setKYCModal] = useState<any>(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  const [kycStatus, setKycStatus] = useState("");

  useEffect(() => {
    fetchUserCountry(currencyInfo)
      .then((res) => {
        setDefaultCountryCode(res);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
    getKycDetails();
  }, []);
  useEffect(() => {
    if (defaultCountryCode) {
      form.setFieldsValue({ code: defaultCountryCode });
    }
  }, [defaultCountryCode, form]);

  const getKycDetails = async () => {
    try {
      const response: any = await GET(API.GET_KYC_BY_STATUS + User?.id, null);
      if (response?.status) {
        setKycStatus(response?.data?.isissued);
      }
    } catch (error) {
    } finally {
    }
  };
  const onFinish = async (val: any) => {
    const phone_number = `+${val?.code} ${val?.phoneNumber}`;
    if (phone_number === User?.phoneNumber) {
      notificationApi.error({ message: t("you_cannot_gift_to_yourself") });
    } else {
      try {
        setIsLoading(true);
        const { code, phoneNumber, ...metals } = val;
        let obj: any = [];
        Object.keys(metals).map((item: any) => {
          if (item === "cash") {
            obj.push({
              metal_type: item,
              amount: Number(val[item] / CurrencyInfo?.current?.exchange_rate),
            });
          } else {
            obj.push({
              metal_type: item,
              amount: val[item],
            });
          }
        });
        const response: any = await PUT(
          API.GIFT_SOMEONE +
            `?user_id=${User?.id}&phoneNumber=${encodeURIComponent(
              phone_number
            )}`,
          obj
        );

        if (response?.status) {
          response?.data?.map((item: string) => {
            notificationApi.success({ message: changeMetalTypeFormat(item) });
          });
          form?.resetFields();
        } else {
          notificationApi.error({
            message: changeMetalTypeFormat(
              response.message || "Can't Send Gift To These Number"
            ),
          });
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGiftSubmit = () => {
    if (kycStatus !== "accepted") {
      setKYCModal(true);
    } else {
      form.submit();
    }
  };

  const selectBefore = (
    <Form.Item
      name="code"
      rules={[{ required: true, message: t("required") }]}
      style={{ margin: 0, height: "5vh" }}
      className="app-GiftScreen-items-selectBefore"
    >
      <Select
        style={{ width: "100px", color: "#ffffffee" }}
        options={CurrencyInfo?.currencyInfo.map((item: any) => ({
          value: item.dail_code,
          label: (
            <>
              <img
                alt={item?.country_code}
                src={`https://flagsapi.com/${item?.country_code}/flat/24.png`}
              />
              &nbsp;
              {"+"}
              {item.dail_code}
            </>
          ),
        }))}
      />
    </Form.Item>
  );
  const kycStatusType = (text: any) => {
    if (text === statusType.not_submitted) {
      return "Not submitted";
    }
    if (text === statusType.pending) {
      return "Pending";
    }
    if (text === statusType.reject) {
      return "Reject";
    }
  };

  return (
    <div>
      <AppPageHeader title={t("send_gift")}></AppPageHeader>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row>
          <Col md={2} xs={12}></Col>
          <Col md={7} xs={12} style={{ padding: "20px" }}>
            <Row className="app-GiftScreen">
              {User?.kyc_status !== "accepted" ? (
                <>
                  <Alert
                    message={
                      User?.kyc_status === statusType.pending
                        ? `KYC is ${kycStatusType(User?.kyc_status)}`
                        : `KYC is ${User?.kyc_status}`
                    }
                    description={
                      "You can access this feature after Kyc approval"
                    }
                    type={"error"}
                    showIcon
                    style={{ marginBottom: 20, fontSize: "18px" }}
                  />{" "}
                </>
              ) : null}
              <Col md={12}>
                <Form.Item
                  className="app-GiftScreen-items"
                  name="phoneNumber"
                  rules={[
                    { required: true, message: t("required") },
                    {
                      pattern: /^[0-9]*$/, // Only allows numeric input
                      message: t("please_enter_valid_phone_number"),
                    },
                  ]}
                  label={
                    <div className="app-GiftScreen-txt2">
                      {t("recipients_phone_number")}
                    </div>
                  }
                >
                  <Input
                    size="large"
                    addonBefore={selectBefore}
                    placeholder={t("enter_phone_number")}
                  />
                </Form.Item>
              </Col>
              {Object.values(metalType).map((item: any) => (
                <Col md={6}>
                  <Form.Item
                    className="app-GiftScreen-items"
                    label={
                      <div className="app-GiftScreen-txt2">
                        {t("gift")} {changeMetalTypeFormat(item)}
                      </div>
                    }
                    name={item}
                    initialValue={0}
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        message: t("field_gt_0_err"),
                      },
                      {
                        required: true,
                        message: t("this_field_is_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      disabled={isLoading}
                      addonAfter={
                        <div style={{ color: "#ffffffee" }}>
                          {item === "cash"
                            ? CurrencyInfo?.current?.currency_code
                            : t("gram")}
                        </div>
                      }
                      type="number"
                    />
                  </Form.Item>
                </Col>
              ))}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  className="app-GiftScreen-Buttons"
                  loading={isLoading}
                  onClick={handleGiftSubmit}
                  style={{ width: "150px", height: "40px" }}
                >
                  {t("submit")}
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
      <KycStatusInfo
        data={kycStatus}
        User={User}
        isOpen={kycModal}
        onClose={() => setKYCModal(false)}
      />
    </div>
  );
}

export default SendGift;
