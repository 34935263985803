import { Drawer } from "antd";
import { useSelector } from "react-redux";

import SiderScreen from "./sider";

function MobileDrawer(props: any) {
  const User = useSelector((state: any) => state.auth.user);
  return (
    <Drawer
      placement={"left"}
      closable={false}
      onClose={() => props.close()}
      open={props.open}
      key={"headerdrwer"}
      width={230}
      bodyStyle={{ padding: "0px" }}
    >
      <div className="app-sidebar-mobile">
        <SiderScreen onChange={() => props.close()} />
      </div>
    </Drawer>
  );
}

export default MobileDrawer;
