import { Card } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import planningImg from "../../../../assets/images/planning.png";
import schedulingImg from "../../../../assets/images/schedule.png";
import withdrawImg from "../../../../assets/images/transfer.png";
import "../styles.scss";
import { useTranslation } from "react-i18next";

export default function HowItWorks(props: any) {
  const { t } = useTranslation();
  return (
    <div className="app-SipScreen-box1">
      <br />
      <div className="app-SipScreen-txt3">{t("how_it_works")}</div>
      <Container>
        <Row>
          <Col md={4}>
            <Card className="app-SipScreen-HowItWorks-card1">
              <div className="app-SipScreen-HowItWorks-box3">
                <img src={planningImg} alt="" />
                <br />
                <div className="app-SipScreen-txt5">
                  {t("create_custom_plan")}
                </div>
                <div className="app-SipScreen-txt6">
                  {t("begin_custom_journey")}
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="app-SipScreen-HowItWorks-card1">
              <div className="app-SipScreen-HowItWorks-box3">
                <img
                  src={schedulingImg}
                  alt=""
                  className="app-Sipscreen-icon-img"
                />
                <br />
                <div className="app-SipScreen-txt5">
                  {t("follow_your_plan")}
                </div>
                <div className="app-SipScreen-txt6">
                  {t("follow_your_plan_description")}
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="app-SipScreen-HowItWorks-card1">
              <div className="app-SipScreen-HowItWorks-box3">
                <img
                  src={withdrawImg}
                  alt=""
                  className="app-Sipscreen-icon-img"
                />
                <br />
                <div className="app-SipScreen-txt5">
                  {t("withdraw_anytime")}
                </div>
                <div className="app-SipScreen-txt6">
                  {t("withdraw_anytime_description")}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
