import React, { useEffect, useState } from "react";
import "../styles.scss";
import { Button, List } from "antd";
import { GET } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import { useSelector } from "react-redux";
import { GiTrade } from "react-icons/gi";
import { IoWalletOutline } from "react-icons/io5";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineSip, MdOutlineVerifiedUser, MdRedeem } from "react-icons/md";
import { changeMetalTypeFormat } from "../../../shared/helpers/functions";
import { useNavigate } from "react-router-dom";

function NotificationPopover(props: any) {
  const navigate = useNavigate();
  const User = useSelector((state: any) => state?.auth?.user);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [meta, setMeta] = useState<any>();

  useEffect(() => {
    getNotification();
  }, []);
  const getNotification = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.NOTIFICATION_ALL + User?.id + `?order=DESC&page=1&take=4`,
        null
      );
      if (response?.status) {
        setData(response?.data?.data);
        setMeta(response?.data?.meta);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const IconType = (text: string) => {
    if (text === "trade") {
      return <GiTrade />;
    }
    if (text === "wallet") {
      return <IoWalletOutline />;
    }
    if (text === "gift") {
      return <AiOutlineGift />;
    }
    if (text === "kyc") {
      return <MdOutlineVerifiedUser />;
    }
    if (text === "sip") {
      return <MdOutlineSip />;
    }
    if (text === "redeem") {
      return <MdRedeem />;
    }
  };
  return (
    <div className="App-NotificationPopover">
      <div className="App-NotificationPopover-txt1">Notifications</div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item: any, index) => (
          <div
            className="App-NotificationPopover-box1"
            onClick={() => navigate(`/auth/notification`)}
          >
            <div className="App-NotificationPopover-active"></div>
            <div className="App-NotificationPopover-icon ">
              {IconType(item?.type)}
            </div>
            <div className="app-NotificationPopover-Txt3">
              {item?.type === "trade" || item?.type === "kyc"
                ? `${item?.type} ${changeMetalTypeFormat(item?.status)}`
                : changeMetalTypeFormat(item?.status)}
              <br />
              <span className="app-NotificationPopover-Txt2">
                {changeMetalTypeFormat(item?.comment)}
              </span>
            </div>
          </div>
        )}
        loading={isLoading}
      />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          className="app-NotificationPopover-Button"
          onClick={() => navigate(`/auth/notification`)}
        >
          View all Notifications
        </Button>
      </div>
    </div>
  );
}

export default NotificationPopover;
