import React, { useState } from "react";
import "./styles.scss";
import { Button, Form, Input, Modal, Select, Radio, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { IoPhonePortraitOutline } from "react-icons/io5";
import LoginLogo from "../../assets/images/logo-login.jpg";
import { POST } from "../../utlis/apiCalls";
import API from "../../config/api";
import { BiErrorCircle } from "react-icons/bi";

import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import {
  isLoggedIn,
  setInvoiceToken,
  setToken,
  setVerificationTokens,
} from "../../Redux/slice/authSlice";

const LoginModal = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currency = useSelector((state: any) => state.currencyInfo.currencyInfo);
  const WatoToken = useSelector((state: any) => state.auth.verificationToken);
  const [isLoading, setIsLoading] = useState(false);

  const [verification, setverification] = useState(false);
  const [otpType, setOtpType] = useState("whatsapp");
  const [autho, setautho] = useState<any>(null);
  const [data, setdata] = useState<any>({});
  const [error, setError] = useState<any>(null);

  const checkuser = async () => {
    try {
      let user: any = auth.currentUser;
      if (user?.phoneNumber) {
        signOut(user);
      }
    } catch (err) {
      console.log("checkuser err", err);
    }
  };

  const sendOTP = (values: any) => {
    setOtpType(values?.otpType);
    if (values?.otpType === "whatsapp") {
      sendWhatsappOTP(values);
    } else {
      sendGoogleOTP(values);
    }
  };

  const sendWhatsappOTP = async (values: any) => {
    try {
      setIsLoading(true);
      setdata(values);
      let reqBody = {
        name: "code",
        phoneNumber: `+${values.code} ${values.phone}`,
      };
      let res: any = await POST(API.REQUEST_OTP, reqBody);
      if (res?.status) {
        message.success("OTP sented to your whatsapp");
        dispatch(setVerificationTokens(res?.token));
        setverification(true);
      } else {
        setError("Whatsapp Login Failed.. Try later");
      }
      setIsLoading(false);
    } catch (err) {
      setError("Whatsapp Login Failed.. Try later");
      setIsLoading(false);
      console.log(err);
    }
  };

  const sendGoogleOTP = async (values: any) => {
    try {
      setdata(values);
      setIsLoading(true);
      checkuser();
      setError("");
      let recaptchas = await new RecaptchaVerifier(auth, "recaptcha", {});
      let phone = `+${values.code}${values.phone}`;
      let checkPhone: any = await signInWithPhoneNumber(
        auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
      } else {
        message.error("Phone Login Failed");
        setError("Phone Login Failed.. Try later");
      }
      setIsLoading(false);
    } catch (err: any) {
      console.log("err -- ", err);
      if (err?.message?.includes("invalid-phone")) {
        setError("Invalid Phone number Try again");
      } else {
        setError("Phone Login Failed.. Try later");
      }
      setverification(false);
      setIsLoading(false);
    }
  };

  const verifyOTP = async (values: any) => {
    if (otpType === "whatsapp") {
      await vertifyWhatsappOTP(values);
    } else {
      await vertifyGoogleOTP(values);
    }
  };

  const vertifyWhatsappOTP = async (values: any) => {
    try {
      setIsLoading(true);
      let joinVal: string = values?.otp;
      let reqBody = {
        token: WatoToken,
        otp: joinVal,
        phoneNumber: `${data?.code} ${data?.phone}`,
      };
      let res: any = await POST(API.VERIFY_OTP, reqBody);
      if (res?.userId?.status) {
        Login();
      } else {
        setError("Whatsapp Login failed. Please try later.");
      }
    } catch (err: any) {
      setIsLoading(false);
      console.log("Error Otp", err);
      setError("Whatsapp Login failed. Please try later.");
    } finally {
      setIsLoading(false);
    }
  };

  const vertifyGoogleOTP = async (values: any) => {
    try {
      setIsLoading(true);
      let otp = values.otp;
      let verify = await autho.confirm(otp);
      const token = await verify?.user?.getIdToken();
      if (token) {
        Login();
      } else {
        message.error("Login Failed");
        throw new Error("No token found.");
      }
    } catch (err: any) {
      if (err?.message?.includes("invalid-verification")) {
        setError("Invalid Otp, Please try again");
      } else {
        setError("Phone Login failed. Please try later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const Login = async () => {
    try {
      let body = {
        name: "",
        phoneNumber: `+${data?.code} ${data?.phone}`,
        city: "",
        email: "",
        role: 1,
      };
      let response: any = await POST(API.LOGIN, body);
      setIsLoading(false);
      if (response?.id) {
        message.success("Logined successfully");
        dispatch(isLoggedIn(response));
        dispatch(setToken(response?.token));
        dispatch(setInvoiceToken(response?.taxgoToken));
        if (response?.role === 2) {
          navigate("/admin/dashboard");
        } else {
          navigate("/auth/home");
        }
      } else {
        if (response?.statusCode === 401) {
          message.error(response?.message);
        } else {
          message.error("Login Failed.Please try again");
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const PrefixSelector = () => {
    return (
      <Form.Item
        name="code"
        noStyle
        rules={[{ required: true, message: "Please select countrycode" }]}
      >
        <Select
          style={{ width: 110 }}
          size="large"
          defaultActiveFirstOption={true}
        >
          {currency.map((item: any, index: number) => (
            <Select.Option key={index} value={item?.dail_code}>
              <img
                src={`https://flagsapi.com/${item?.country_code}/flat/24.png`}
              />
              &nbsp; +{item?.dail_code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return (
    <Modal
      title={""}
      open={props?.open}
      onCancel={() => props?.close()}
      footer={false}
      width={450}
    >
      <div className="LoginModal-Box">
        <div className="LoginModal-text1">Login to LSG</div>
        <br />
        <img src={LoginLogo} className="LoginModal-img" />
        <Form
          onFinish={verification ? verifyOTP : sendOTP}
          initialValues={{ code: "91", otpType: "whatsapp" }}
        >
          {verification ? (
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Input 6 digit verification code !",
                },
              ]}
            >
              <Input size="large" placeholder="Enter OTP" autoFocus />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Input your phone number!",
                  },
                  { min: 8, message: "Please enter a valid phone number" },
                  { max: 14, message: "Please enter a valid phone number" },
                ]}
              >
                <Input
                  addonBefore={<PrefixSelector />}
                  suffix={<IoPhonePortraitOutline size={20} color="grey" />}
                  placeholder="Enter Mobile Number"
                  size="large"
                />
              </Form.Item>

              <div id="recaptcha"></div>
              <Form.Item name="otpType">
                <Radio.Group>
                  <Radio value="whatsapp"> WhatsApp OTP </Radio>
                  <Radio value="sms">SMS OTP</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          {error ? (
            <div className="LoginScreen-errortxt">
              <BiErrorCircle />
              &nbsp;
              {error}
            </div>
          ) : null}
          <Form.Item>
            <Button
              style={{ height: 50, border: 0, backgroundColor: "#00a56e" }}
              block
              htmlType="submit"
              loading={isLoading}
            >
              Proceed
            </Button>
          </Form.Item>

          <div className="LoginModal-text2">
            By clicking proceed, I agree to{" "}
            <a href="https://digigold.luckystargold.com/term-condition">T&C</a>{" "}
            and{" "}
            <a href="https://digigold.luckystargold.com/privacy">
              Privacy Policy
            </a>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default LoginModal;
