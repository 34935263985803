import { Pagination, Tag, notification } from "antd";
import { useEffect, useState } from "react";
import { GET } from "../../../../utlis/apiCalls";
import API from "../../../../config/api";
import Table, { ColumnsType } from "antd/es/table";
import {
  StatusColor,
  TypeColor,
  changeMetalTypeFormat,
  metalColor,
} from "../../../../shared/helpers/functions";
import moment from "moment";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function CustomerTradeTable(props: any) {
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState<any>(false);
  const [userTrade, setUserTrade] = useState<any>();
  const [meta, setMeta] = useState<any>();
  const [pageNo, setPageNo] = useState<any>(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getUserTrade();
  }, [pageNo, props?.refresh]);

  const getUserTrade = async () => {
    try {
      setisLoading(true);
      const response: any = await GET(
        API.TRADING_USERS_LIST +
          `${props?.customerId}?order=DESC&page=${pageNo}&take=${pageSize}`,
        null
      );
      if (response?.status) {
        setUserTrade(response?.data?.data);
        setMeta(response?.data?.meta);
      } else {
        setUserTrade([]);
        setMeta([]);
      }
    } catch (error) {
    } finally {
      setisLoading(false);
    }
  };
  const pagination = (page: any, take: any) => {
    setPageNo(page);
    setPageSize(take);
  };
  const handlePageSize = (current: number, page: number) => {
    setPageNo(current);
    setPageSize(page);
  };
  const columns: ColumnsType<any> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any) => (
        <span style={{ textTransform: "capitalize" }}>
          <Tag color={TypeColor(text)}>{text}</Tag>
        </span>
      ),
    },
    {
      title: "Metal Type",
      dataIndex: "metal_type",
      key: "metal_type",
      render: (text: string) => (
        <span>
          <Tag color={metalColor(text)}>{changeMetalTypeFormat(text)}</Tag>
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => (
        <span style={{ textTransform: "capitalize" }}>
          <Tag color={StatusColor(text)}>
            {text}
          </Tag>
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "view",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <MdOutlineRemoveRedEye
          size={25}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/admin/trddetails/${record.id}`)}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={userTrade}
        pagination={false}
        className="commen-table-custom-scrollbar"
        size="small"
      />
      <Pagination
        current={pageNo}
        pageSize={pageSize}
        total={meta?.itemCount}
        defaultCurrent={1}
        responsive={true}
        defaultPageSize={pageSize}
        disabled={false}
        onShowSizeChange={handlePageSize}
        hideOnSinglePage={true}
        onChange={(page, take) => pagination(page, take)}
        style={{ marginTop: "20px", textAlign: "center", marginBottom: "20px" }}
      />
    </>
  );
}
