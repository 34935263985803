import "../styles.scss";

import { List, Pagination } from "antd";

import LogItems from "./logItems";

function DataList(props: any) {
  return (
    <div>
      <div style={{ margin: 10 }} />
      <List
        itemLayout="horizontal"
        dataSource={props?.data}
        bordered={false}
        renderItem={(item: any, index) => (
          <LogItems item={item} refresh={() => props?.refresh()} />
        )}
        loading={props?.isLoading}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          className="NotificationScreen-pagination"
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          onShowSizeChange={props?.handlePageSize}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={props?.pageSize < 10 ? true : false}
          onChange={props?.handlePageChange}
        />
      </div>
    </div>
  );
}

export default DataList;
