import { Tag } from "antd";
import "../styles.scss";
import moment from "moment";

import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  changeMetalTypeFormat,
  metalColor,
  StatusColor,
} from "../../../../shared/helpers/functions";

function HistoyListItems(props: any) {
  const { t } = useTranslation();

  const currentInfo = useSelector((state: any) => state?.currencyInfo?.current);

  return (
    <div className="app-Sipscreen-DataTable-ListItems">
      <Row
        className="g-2 w-100"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Col md={4} xs={12}>
          <div className="app-Sipscreen-DataTable-txt1">
            {`${t("amount")} (${currentInfo?.currency_code})`}&nbsp;:&nbsp;
            <span>
              {props?.data?.purchased_amount *
                currentInfo?.exchange_rate?.toFixed(2)}
            </span>
          </div>
          <div className="app-Sipscreen-DataTable-txt1">
            {`${t("weight")} (${t("gram")})`}&nbsp;:&nbsp;
            <span>
              <Tag
                style={{
                  backgroundColor: "#00a56e49",
                  color: "#00a56e",
                  border: "none",
                  padding: "5px",
                  fontSize: "16px",
                }}
              >
                {props?.data?.weight.toFixed(2)}
              </Tag>
            </span>
          </div>
        </Col>
        <Col md={2} xs={12}>
          <Tag
            style={{
              backgroundColor: "transparent",
              color: metalColor(props?.data?.metal_type),
              border: `1px solid ${metalColor(props?.data?.metal_type)}`,
              padding: "5px",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            {changeMetalTypeFormat(props?.data?.metal_type)}
          </Tag>
        </Col>
        <Col md={2} xs={12}>
          <div className="app-Sipscreen-DataTable-txt1">
            {props?.data?.type}
          </div>
        </Col>
        <Col md={2} xs={12}>
          <div className="app-Sipscreen-DataTable-txt1">
            <Tag
              style={{
                backgroundColor: "transparent",
                color: StatusColor(props?.data?.status),
                padding: "5px",
                fontSize: "16px",
                border: `1px solid ${StatusColor(props?.data?.status)}`,
              }}
            >
              {props?.data?.status}
            </Tag>
          </div>
        </Col>
        <Col md={2} xs={12}>
          <div className="app-Sipscreen-DataTable-txt1">
            Created On :<br />
            {moment(props?.data?.createdAt).format("D MMM, YYYY")}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default HistoyListItems;
