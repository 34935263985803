import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import { GET } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import { useSelector } from "react-redux";

function WalletScreen() {
  const user = useSelector((state: any) => state?.auth?.user);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<any>([]);
  const [kycStatus, setKycStatus] = useState<any>();

  useEffect(() => {
    fetchWallet();
    getKycDetails();
  }, []);

  const fetchWallet = async () => {
    try {
      const response: any = await GET(API.GET_WALLET + user?.id, null);
      if (response.status) {
        setData(response?.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const getKycDetails = async () => {
    try {
      const response: any = await GET(API.GET_KYC_BY_STATUS + user?.id, null);
      if (response?.status) {
        setKycStatus(response?.data?.isissued);
      }
    } catch (error) {
    } finally {
    }
  };

  return (
    <div className="app-WalletScreen">
      <Container>
        <Row className="g-3">
          <Col md={{ order: 1, span: 5 }} xs={{ order: 1, span: 12 }}>
            <Section1 data={data} user={user} kycStatus={kycStatus} />
          </Col>
          <Col md={{ order: 2, span: 7 }} xs={{ order: 3, span: 12 }}>
            <Section4 />
          </Col>
          <Col md={{ order: 3, span: 5 }} xs={{ order: 2, span: 12 }}>
            <Section3 data={data} loading={loading} />
          </Col>
          <Col md={{ order: 4, span: 7 }} xs={{ order: 4, span: 12 }}>
            <Section2 />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WalletScreen;
