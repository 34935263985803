import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import insights1 from "../../../assets/images/insights-1.jpg";
import insights2 from "../../../assets/images/insights-2.jpg";
import insights3 from "../../../assets/images/insights-3.jpg";
import insights4 from "../../../assets/images/insights-4.jpg";

function Section5() {
  const { t } = useTranslation();

  const aboutGold = [
    {
      img: insights1,
      titil: `${t("Digital_Gold_Explained")}`,
      desc: `${t("When_technology")}`,
    },
    {
      img: insights2,
      titil: `${t("Everything_You")}`,
      desc: `${t("Gold_is_not")}`,
    },
    {
      img: insights3,
      titil: `${t("Busting_Some_Myths")}`,
      desc: `${t("You_don’t_need")}`,
    },
    {
      img: insights4,
      titil: `${t("Tracking_Gold")}`,
      desc: `${t("Gold_has_had")}`,
    },
  ];
  return (
    <div className="homeScreen-section5">
      <Container>
        <div className="homeScreen-text10 center">
          {t("our")} <span className="homeScreen-text2">{t("insights")}</span>{" "}
        </div>
        <Row className="mt-5 justify-content-center">
          {aboutGold.map((item) => (
            <Col xs={12} sm={6} md={5} lg={4} xl={3}>
              <Fade delay={100} style={{ height: "100%" }}>
                <div className="FifthScreen-card">
                  <img src={item.img} alt="carditems" />
                  <div className="p-3">
                    <div className="homeScreen-text8">{item.titil}</div>
                    <div className="homeScreen-text11">{item.desc}</div>
                  </div>
                </div>
              </Fade>
            </Col>
          ))}
        </Row>
        <Row className="mt-5">
          <Col lg={3}></Col>
          <Col lg={6}>
            <Fade delay={100}>
              <div className="FifthScreen-form">
                <div className="homeScreen-text8">{t("From_the_media")}</div>
                <div className="homeScreen-text11">
                  {t("If_you_are_looking")}{" "}
                  <a href="mailto:luckystargoldllc@gmail.com">
                    sales@luckystargold.com
                  </a>
                </div>
              </div>
            </Fade>
          </Col>
          <Col lg={3}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Section5;
