import "./styles.scss";
import { Container } from "react-bootstrap";

import WebsiteFooter from "../../components/websiteFooter";
import WebsiteHeader from "../../components/websiteHeader";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

function TermCondition() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <WebsiteHeader />
      <div className="termScreen">
        <Container>
          <Fade>
            <br />
            <br />
            <div className="termScreen-text1 center">
              {t("terms_and_conditions")}
            </div>
            <br />
            <br />
            <div className="termScreen-text2">
              {t("welcome_to_lsg_digital_gold")}
            </div>
            <br />
            <div className="termScreen-text2">{t("user_responsibility")}</div>
            <div className="termScreen-text3">
              {t("as_a_valued_user_of_lsg_digital_gold_it_is_imperative")}
            </div>
            <br />
            <div className="termScreen-text2">
              {" "}
              {t("third_party_collaboration")}
            </div>
            <div className="termScreen-text3">
              {t("lsg_digital_gold_collaborates_with_esteemed_third_party")}{" "}
            </div>
            <div className="termScreen-text3">
              {t("third_party_partners_bound_by_contractual_agreements")}{" "}
            </div>
            <div className="termScreen-text3">
              {t("regular_audits_and_assessments")}
            </div>
            <br />
            <div className="termScreen-text2"> {t("data_handling")}</div>
            <div className="termScreen-text3">
              {t("security_and_confidentiality_commitment")}
              <br />
              {t("stringent_access_controls_and_authentication_mechanisms")}
            </div>
            <br />
            <div className="termScreen-text2">
              {" "}
              {t("no_unauthorized_transfers")}
            </div>
            <div className="termScreen-text3">
              {t("unequivocal_assurance_no_unauthorized_transfers")}
              <br />
              {t("consent_for_specific_data_usage")}{" "}
            </div>
            <br />
            <div className="termScreen-text2"> {t("consent")}</div>
            <div className="termScreen-text3">
              {t("consent_to_privacy_policy_and_terms")}
              <br />
              {t("consent_encompasses_processing_of_personal_data")}{" "}
            </div>
            <br />
            <div className="termScreen-text2">
              {" "}
              {t("updates_and_modifications")}
            </div>
            <div className="termScreen-text3">
              {t("update_or_modify_terms_and_conditions")}
              <br />
              {t("encouragement_to_review_terms_periodically")}
            </div>
            <br />
            <div className="termScreen-text2"> {t("contact_us")}</div>
            <div className="termScreen-text3">
              {t("contact_us_for_inquiries_concerns_requests")}
            </div>
            <br />
            <Button type="primary" onClick={() => navigate("/contact")}>
              {t("contact_now")}
            </Button>
            <br />
          </Fade>
        </Container>
      </div>
      <WebsiteFooter />
    </div>
  );
}

export default TermCondition;
