import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { Button } from "antd";
import { FaArrowRight } from "react-icons/fa6";
import Charts from "./chart";
import { t } from "i18next";
function Section3() {
  return (
    <div className="homeScreen-section3">
      <Container>
        <Row>
          <Col sm={5} xs={12}>
            <Fade>
              <div className="homeScreen-text10">
                <span className="homeScreen-text2">
                  {t("Better_than_an_FD")}
                </span>{" "}
                ?
              </div>
            </Fade>
            <Fade>
              <div className="homeScreen-text11">{t("A_₹1000_investment")}</div>
            </Fade>
            <br />
            <Fade>
              <Row>
                <Col sm={5} xs={6}>
                  <div className="homeScreen-box7">
                    <div style={{ flex: 1 }}>GOLD</div>
                    <div>
                      <div style={{ marginBottom: 10 }}>₹2204</div>
                    </div>
                  </div>
                </Col>
                <Col sm={5} xs={6}>
                  <div className="homeScreen-box7">
                    <div style={{ flex: 1 }}>FD</div>
                    <div>
                      <div style={{ marginBottom: 10 }}>₹1383</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Fade>
            <br />
            <Fade>
              <div className="homeScreen-text8">{t("Grow_your_wealth")}</div>
              <div className="homeScreen-text3">{t("Start_an_SIP")} </div>
            </Fade>
            <br />
            <br />
            <Fade>
              <Button
                style={{ color: "#000" }}
                icon={<FaArrowRight color="#000" />}
                size="large"
              >
                {t("Start_investing")}
              </Button>
            </Fade>
            <br /> <br />
          </Col>
          <Col sm={7} xs={12}>
            <Fade>
              <Charts />
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Section3;
