import React from "react";
import { Col, Row } from "react-bootstrap";
import "../styles.scss";

import { useSelector } from "react-redux";

function Section2() {
  const liveRate = useSelector((state: any) => state.liveRate.current);

  const calculateOunceRate = (metal: any, gramRate: any) => {
    const findLiveIndex = liveRate.find((item: any) => item.metal === metal);

    let result;
    if (gramRate === "Bid") {
      result = findLiveIndex?.ounceUsdBid?.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else if (gramRate === "Ask") {
      result = findLiveIndex?.ounceUsdAsk?.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return result || "0.00";
  };

  return (
    <div className="app-HomeScreen-Section2">
      <Row className="g-0 w-100">
        <Col md={6}>
          <div className="app-HomeScreen-Section2-Box1">
            <div className="app-HomeScreen-Section2-Box2">
              <div className="app-HomeScreen-Section2-txt1 ">
                SPOT RATE (USD)
              </div>
              <div className="app-HomeScreen-Section2-txt2">GOLD Oz</div>
            </div>
            <div className="app-HomeScreen-Section2-Box2">
              <div className="app-HomeScreen-Section2-txt3">
                BID&nbsp;
                <span>{calculateOunceRate("GOLD 9999", "Bid")}</span>
              </div>
              <div className="app-HomeScreen-Section2-txt4 ">
                ASK&nbsp;
                <span>{calculateOunceRate("GOLD 9999", "Ask")}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="app-HomeScreen-Section2-Box1 Last">
            <div className="app-HomeScreen-Section2-Box2">
              <div className="app-HomeScreen-Section2-txt1 ">
                SPOT RATE (USD)
              </div>
              <div className="app-HomeScreen-Section2-txt2">SILVER Oz</div>
            </div>
            <div className="app-HomeScreen-Section2-Box2">
              <div className="app-HomeScreen-Section2-txt3">
                BID&nbsp;
                <span>{calculateOunceRate("SILVER 999", "Bid")}</span>
              </div>
              <div className="app-HomeScreen-Section2-txt4">
                ASK&nbsp;
                <span>{calculateOunceRate("SILVER 999", "Ask")}</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Section2;
