import { Container } from "react-bootstrap";
import PageHeader from "../../routes/pageHeader";
import { Button, Input, Pagination } from "antd";
import { useEffect, useState } from "react";
import { GET } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import DataTable from "./components/dataTable";
import { IoIosClose } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import useDebounce from "../../../shared/helpers/useDebounce";

export default function CurrencyExchange(props: any) {
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [currencyInfo, setCurrencyInfo] = useState<any>();
  const [meta, setMeta] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [refresh, setRefresh] = useState<boolean>(false);
  useEffect(() => {
    getCurrencyInfo();
  }, [pageNo, pageSize, useDebounce(search, 1500), refresh]);
  const getCurrencyInfo = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.CURRENCY_LIST +
          `?order=ASC&page=${pageNo}&search=${search}&take=${pageSize}`,
        null
      );
      if (response?.status) {
        setCurrencyInfo(response?.data?.data);
        setMeta(response?.data?.meta);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (page: number, pageSize: number) => {
    setPageNo(page);
    setPageSize(pageSize);
  };
  const handlePageSize = (current: number, page: number) => {
    setPageNo(current);
    setPageSize(page);
  };

  return (
    <div>
      {/* {contextHolder} */}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Currency Exchange"}>
          <div className="d-flex">
            {" "}
            <Input
              style={{ width: "200px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<IoSearchOutline size={20} color="gray" />}
              placeholder="Search here.."
              suffix={
                <IoIosClose
                  size={20}
                  color="grey"
                  cursor={"pointer"}
                  onClick={() => setSearch("")}
                />
              }
            />
            <div style={{ width: 20 }} />
            <div>
              <Button type="primary" onClick={() => setRefresh(!refresh)}>
                Refresh
              </Button>
            </div>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <DataTable
            isLoading={isLoading}
            currencyInfo={currencyInfo}
            setRefresh={() => setRefresh(!refresh)}
          />
          <Pagination
            current={pageNo}
            pageSize={pageSize}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            onShowSizeChange={handlePageSize}
            defaultPageSize={pageSize}
            disabled={false}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center", color: "#000" }}
          />
        </div>
      </Container>
    </div>
  );
}
