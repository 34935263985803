// helpers/fetchingDatas.ts
import { logoutChannel } from "../../../logout";

export const useHandleTokenExpiration = () => {
  const handleTokenExpiration = () => {
    logoutChannel.postMessage("Logout");
  };

  return handleTokenExpiration;
};
