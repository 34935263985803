import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Apple from "../../assets/images/store-apple.png";
import Google from "../../assets/images/store-google.png";
function WebsiteFooter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme: any = useSelector((state: any) => state?.theme?.theme);

  const handlenavigate = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };
  return (
    <div
      className="WebsiteFooter"
      style={{ backgroundColor: theme === "dark" ? "black" : "#082413" }}
    >
      <Container>
        <Row>
          <Col sm={3} xs={12}>
            <div className="WebsiteFooter-txt1">{t("head_office")}</div>
            <div className="WebsiteFooter-txt2">
              Dalmook 3 Building , Shop No5, Near Women's Museum Gold Souk,
              Dubai, UAE.
            </div>
            <br />
            <Row>
              <Col sm={6} xs={6}>
                <a
                  href="https://apps.apple.com/in/app/lucky-star-digi-gold/id6504337889"
                  target="_blank"
                >
                  <img src={Apple} className="WebsiteFooter-storeImg" />
                </a>
              </Col>
              <Col sm={6} xs={6}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.lsgdigitalgold&pli=1"
                  target="_blank"
                >
                  <img src={Google} className="WebsiteFooter-storeImg" />
                </a>
              </Col>
            </Row>
            <br />
            <br />
          </Col>
          <Col sm={3} xs={12}></Col>
          <Col sm={3} xs={12}>
            <div className="WebsiteFooter-txt1">{t("phone_number")}</div>
            <div className="WebsiteFooter-txt2">
              <a className="WebsiteFooter-txt2" href="tel:+971565084747">
                +971 56 508 4747
              </a>
            </div>
            <div className="WebsiteFooter-txt2">
              <a className="WebsiteFooter-txt2" href="tel:+971565084242">
                +971 56 508 4242
              </a>
            </div>
            <div className="WebsiteFooter-txt2">
              <a className="WebsiteFooter-txt2" href="tel:043534929">
                0 43 534 929
              </a>
            </div>

            <br />
            <div className="WebsiteFooter-txt1">{t("email")}</div>
            <a
              className="WebsiteFooter-txt2"
              href="mailto:luckystargoldllc@gmail.com"
            >
              sales@luckystargold.com
            </a>
          </Col>
          <Col sm={3} xs={12}>
            <div className="WebsiteFooter-txt1">{t("quick_links")}</div>
            <div
              className="WebsiteFooter-txt2 clickable"
              onClick={() => handlenavigate("/privacy")}
            >
              {t("privacy_policy")}
            </div>
            <div
              className="WebsiteFooter-txt2 clickable"
              onClick={() => handlenavigate("/term-condition")}
            >
              {t("terms_and_condition")}
            </div>
            <div
              className="WebsiteFooter-txt2 clickable"
              onClick={() => handlenavigate("/contact")}
            >
              {t("contact")}
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <hr />
      <div>
        <Container>
          <Row>
            <Col sm={6} xs={12}>
              <div className="WebsiteFooter-txt3">
                Copyrights © 2023. All Rights Reserved luckystargold.com
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <a
                className="WebsiteFooter-txt4"
                href="https://www.semikolen.com/"
                target="_blank"
              >
                {t("powered_by")} : semikolen technologies
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default WebsiteFooter;
