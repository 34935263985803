import { Col, Row } from "react-bootstrap";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function AppPageHeader(props: any) {
  const { head, navigation, showCreateModal, setShowCreateModal } = props;

  const navigate = useNavigate();
  return (
    <div className="app-PageHeader">
      <Row style={{ width: "100%" }}>
        <Col md={6} xs={12}>
          <div className="app-PageHeader-Box1">
            <div>
              <GoArrowLeft
                size={25}
                color={"#fff"}
                onClick={() => navigate(-1)}
              />
            </div>
            <div>
              <div className="app-PageHeader-txt1">{props?.title}</div>
              <div className="app-PageHeader-txt2">
                {`Home ${props?.title == "Home" ? "" : "/" + props?.title}`}
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="app-PageHeader-Box2">{props?.children}</div>
        </Col>
      </Row>
    </div>
  );
}

export default AppPageHeader;
