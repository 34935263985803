import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "antd";

import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";

import { IoCallOutline, IoChatbubblesOutline } from "react-icons/io5";
import { TfiCommentsSmiley } from "react-icons/tfi";
import { GrLocation } from "react-icons/gr";

import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

import ContactSection1 from "./components/section1";
import ContactSection2 from "./components/section2";

function ContactScreen() {
  const { t } = useTranslation();

  const cards = [
    {
      header: "Chat to sales",
      desc: "Speake to our freindly team",
      icons: <TfiCommentsSmiley color="#fff" size={25} />,
      Links: "sales@luckystargold.com",
    },
    {
      header: "Chat to support",
      desc: "We're here to help",
      icons: <IoChatbubblesOutline color="#fff" size={25} />,
      Links: "sales@luckystargold.com",
    },
    {
      header: "Visit us",
      desc: "Visit our office HQ",
      icons: <GrLocation color="#fff" size={25} />,
      Links: "View on Google Maps",
    },
    {
      header: "Call us",
      desc: "Mon-Fri from 9am to 5pm",
      icons: <IoCallOutline color="#fff" size={25} />,
      Links: "+971 56 508 4747",
    },
  ];

  return (
    <div>
      <WebsiteHeader />
      <div className="contactScreen">
        <Container>
          <Fade>
            <br />
            <br />
            <div className="contactScreen-text1 center">
              <span className="contactScreen-text2">Contact </span>our freindly
              team
            </div>
            <div className="contactScreen-text3 center">
              Let us know how we can help
            </div>
          </Fade>
          <br />
          <br />
          <Row>
            {cards.map((items: any, index: any) => {
              return (
                <Col sm={3} xs={12}>
                  <Fade>
                    <Card className="contactScreen-Box1">
                      <div className="contactScreen-Box2 center">
                        {items?.icons}
                      </div>
                      <br />
                      <div>{items?.header}</div>
                      <div
                        style={{ marginBottom: "10px" }}
                        className="contactScreen-text3"
                      >
                        {items?.desc}
                      </div>
                      <div>
                        <a className="contactScreen-Box1-text1">
                          {items?.Links}
                        </a>
                      </div>
                    </Card>
                  </Fade>
                </Col>
              );
            })}
          </Row>
          <br />
        </Container>
      </div>
      <ContactSection2 />
      <ContactSection1 />
      <WebsiteFooter />
    </div>
  );
}

export default ContactScreen;
