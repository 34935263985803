import "./styles.scss";
import WebsiteHeader from "../../components/websiteHeader";
import WebsiteFooter from "../../components/websiteFooter";
import { Container } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <WebsiteHeader />
      <div className="PrivacyPolicyScreen">
        <Container>
          <Fade>
            <br />
            <br />
            <div className="PrivacyPolicyScreen-text1 center">
              {t("privacy_policy")}
            </div>
            <br />
            <br />
            <div className="PrivacyPolicyScreen-text2">
              {t("privacy_policy")}
            </div>
            <div className="PrivacyPolicyScreen-text3">
              {" "}
              {t("at_lsg_digital_gold_we_highly_value_your_privacy")}
            </div>
            <br />
            <div className="PrivacyPolicyScreen-text2">
              {t("data_retention")}
            </div>
            <div className="PrivacyPolicyScreen-text3">
              {t("retain_your_personal_information_for_as_long_as_necessary")}
            </div>
            <br />
            <div className="PrivacyPolicyScreen-text2">{t("your_rights")}</div>
            <div className="PrivacyPolicyScreen-text3">
              {t(
                "you_have_the_right_to_access_update_correct_or_delete_your_personal_information"
              )}
            </div>
            <br />
            <div className="PrivacyPolicyScreen-text2">
              {t("updates_to_the_privacy_policy")}
            </div>
            <div className="PrivacyPolicyScreen-text3">
              {t(
                "lsg_digital_gold_reserves_the_right_to_update_or_modify_this_privacy_policy"
              )}
            </div>
            <br />
            <div className="PrivacyPolicyScreen-text2">
              {" "}
              {t("protection_of_information")}
            </div>
            <div className="PrivacyPolicyScreen-text3">
              {t("lsg_digital_gold_employs_strict_security_measures")}
            </div>
            <br />
            <div className="PrivacyPolicyScreen-text2">{t("contact_us")}</div>
            <div className="PrivacyPolicyScreen-text3">
              {t("contact_lsg_digital_gold_customer_service")}
            </div>
            <div className="PrivacyPolicyScreen-text3">
              {t("phone_number")}: +971 56 508 4747
            </div>
            <div className="PrivacyPolicyScreen-text3">
              {t("dedicated_to_addressing_your_inquiries")}
            </div>
            <br />
            <Button type="primary" onClick={() => navigate("/contact")}>
              {t("contact_now")}
            </Button>
            <br />
            <br />
            <br />
          </Fade>
        </Container>
      </div>
      <WebsiteFooter />
    </div>
  );
}

export default PrivacyPolicy;
