import { Button, Modal, Spin, Tag, message, notification } from "antd";
import { useState } from "react";
import "./styles.scss";

import { LoadingOutlined } from "@ant-design/icons";

import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";

import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import API from "../../config/api";
import { metalNameConversion } from "../../shared/helpers/functions";
import { POST } from "../../utlis/apiCalls";

function TradeSummary(props: any) {
  const [form] = useForm();
  const { t } = useTranslation();

  const currency = useSelector((state: any) => state?.currencyInfo?.current);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  const handleclick = async () => {
    setIsLoading(true);
    try {
      const obj = {
        user_id: props?.User?.id,
        metal_type: metalNameConversion(props?.data?.item),
        amount: Number(props?.data?.rate),
        type: props?.data?.type.toLowerCase(),
        taxgoToken: props?.User?.taxgoToken,
      };
      let response: any = await POST(API.TRADING_ADD, obj);
      if (response?.status) {
        setIsLoading(false);
        message.success("successfully initialated");
        props?.onClose();
        props?.refreshTrade();
      } else {
        setIsLoading(false);
        props?.onClose();
      }
    } catch {}
  };
  return (
    <div>
      {contextHolder}
      <Modal
        open={props?.isOpen}
        onCancel={() => {
          form.resetFields();
          props?.onClose();
        }}
        width={400}
        className="app-TradeScreen-TradeScreenModal"
        okText={t("submit")}
        cancelText={t("cancel")}
        onOk={form?.submit}
        cancelButtonProps={{
          className: "text-yellow-300 border-yellow-300",
        }}
        footer={false}
      >
        <div className="app-TradeScreen-TradeScreenModal-Box1">
          <Container fluid>
            <div className="app-TradeScreen-TradeScreenModal-Box3">
              <br />
              <br />
              <div className="app-TradeScreen-TradeScreenModal-Box1-txt1">
                Confirmation Purchase{" "}
              </div>
              <div>Payment Summary </div>
              <hr className="app-TradeScreen-TradeScreenModal-Underline" />
              <div className="app-TradeScreen-TradeScreenModal-Box2">
                <div>Metal type</div>
                <Tag className="app-TradeScreen-TradeScreenModal-Box2-Tag a">
                  {props?.data?.item}
                </Tag>
              </div>
              <div className="app-TradeScreen-TradeScreenModal-Box2">
                {" "}
                <div>Gold weight</div>
                <Tag className="app-TradeScreen-TradeScreenModal-Box2-Tag b">
                  {props?.data?.gram} g
                </Tag>
              </div>
              <div className="app-TradeScreen-TradeScreenModal-Box2">
                {" "}
                <div>Gold price</div>
                <Tag className="app-TradeScreen-TradeScreenModal-Box2-Tag d">{`${
                  currency.currency_code
                } ${Number(props?.data?.rate).toFixed(2)}`}</Tag>
              </div>
            </div>
          </Container>
          <div
            style={{ width: "100%", borderBottom: "1px solid #f4f4f5" }}
          ></div>
          <br />
          {props?.walletCash?.balance < props?.data?.rate ? (
            <div className="app-TradeScreen-TradeScreenModal-Error">
              <strong style={{ color: "red" }}>Insufficiant balance,</strong>{" "}
              but you can still proceed with payment,It will reflect in your
              wallet after admin approval
            </div>
          ) : null}
          <div className="app-TradeScreen-TradeScreenModal-footer">
            <div>Continue with payment:</div>
            <Button onClick={handleclick}>
              {isLoading ? (
                <Spin
                  indicator={<LoadingOutlined color="#fff" spin />}
                  size="small"
                />
              ) : props?.data?.type === "sell" ? (
                "Confirm Sale"
              ) : (
                `Pay ${Number(props?.data?.rate).toFixed(2)} AED`
              )}
            </Button>
          </div>
          <br />
        </div>
      </Modal>
    </div>
  );
}

export default TradeSummary;
