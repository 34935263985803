import {
  Card,
  Form,
  InputNumber,
  Modal,
  Radio,
  Space,
  Table,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { CiEdit } from "react-icons/ci";
import "../styles.scss";
import { useForm } from "antd/es/form/Form";
import { PUT } from "../../../../utlis/apiCalls";
import API from "../../../../config/api";
import { FaArrowRight } from "react-icons/fa6";
export default function DataTable(props: any) {
  const [form] = useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isLoading2, setIsLoading2] = useState<boolean>();
  const columns: ColumnsType<any> = [
    {
      title: "Country Name",
      dataIndex: "country_name",
      key: "country_name",
    },
    {
      title: "Dial Code",
      dataIndex: "dail_code",
      key: "dail_code",
    },

    {
      title: "Currency Name",
      dataIndex: "currency_name",
      key: "currency_name",
    },
    {
      title: "Currency Code",
      dataIndex: "currency_code",
      key: "currency_code",
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchange_rate",
      key: "exchange_rate",
    },
    {
      title: "is Visible to User",
      dataIndex: "is_visible",
      key: "is_visible",
      render: (text, record) => <div>{text ? "yes" : "no"}</div>,
    },
    {
      title: "Update",
      dataIndex: "update",
      key: "update",
      render: (text, record) => (
        <CiEdit
          size={25}
          style={{ cursor: "pointer" }}
          onClick={() => {
            form.setFieldsValue(record);
            setSelectedRecord(record);
            setIsModalOpen(true);
          }}
        />
      ),
    },
  ];
  const updateCurrencyInfo = async (val: any) => {
    try {
      setIsLoading2(true);
      const response: any = await PUT(
        API.CURRENCY_UPDATE + selectedRecord?.id,
        val
      );
      if (response?.status) {
        notificationApi.success({
          message: "Successfully Update Currency Information",
        });
        props?.setRefresh();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (error) {
    } finally {
      setIsLoading2(false);
    }
  };
  return (
    <>
      {contextHolder}
      <Card className="commen-table-Card">
        <Table
          loading={props?.isLoading}
          columns={columns}
          dataSource={props?.currencyInfo}
          pagination={false}
          className="commen-table-custom-scrollbar"
          size="small"
        />
      </Card>
      <Modal
        open={isModalOpen}
        onCancel={() => {
          form.resetFields();
          setIsModalOpen(false);
        }}
        onOk={form.submit}
        okText={"Submit"}
        okButtonProps={{ loading: isLoading2 }}
        cancelButtonProps={{ loading: isLoading2 }}
      >
        <div className="admin-CurrencyScreen-txt2">Update Currency info</div>
        <Form
          onFinish={updateCurrencyInfo}
          form={form}
          layout="vertical"
          //labelCol={{ span: 8 }}
          //wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label={
              <div className="admin-CurrencyScreen-txt8">
                Is Visible to User
              </div>
            }
            name="is_visible"
          >
            <Radio.Group
            //   onChange={(e: any) => {
            //     setPaymentType(e?.target?.value);
            //   }}
            //defaultValue={paymentType}
            >
              <Space direction="horizontal" style={{ paddingLeft: 20 }}>
                <Radio value={true} style={{ paddingRight: 10 }}>
                  Yes
                </Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <div className="admin-CurrencyScreen-txt8">
                Exhange Rate from UAE Dirham to {selectedRecord?.currency_name}
              </div>
            }
          >
            <InputNumber
              disabled={true}
              addonAfter={"AED"}
              value={1}
              readOnly={true}
            />
          </Form.Item>
          <Form.Item
            name="exchange_rate"
            rules={[
              {
                type: "number",
                message: "The amount should be a numeric value.",
              },
              {
                required: true,
                message: "Please Enter the Desired Amount",
              },
            ]}
          >
            <InputNumber
              disabled={isLoading2}
              addonAfter={selectedRecord?.currency_code}
              min={0}
              type="number"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
